import { Injectable } from '@angular/core';
import { urls } from '../../environments/environment';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';
import { ActionsService } from './ActionService.service';

@Injectable({ providedIn: 'root' })
export class VisitService {
	business() { return JSON.parse(localStorage.getItem("business")).user_id };
	constructor(private http: HttpService, public global: ActionsService) { }

	

    viewVisit(data, load) {
		
		return new Promise((resolve, reject) => {
			this.http.get(urls.visit  + '?token='+this.global.token()+'& start='+data.start +'& limit='+data.limit+'& search='+data.search, load)
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
	viewMovement(data, load) {
		
		return new Promise((resolve, reject) => {
			this.http.get(urls.movement  + '?token='+this.global.token()+'& start='+data.start +'& limit='+data.limit+'& visit_id='+data.visit_id, load)
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
	
	detailVisit(id,load) {
		
		return new Promise((resolve, reject) => {
			this.http.get(urls.visit +'/'+id+ '?token='+this.global.token(), load)
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
	createVisit(data) {
	
		return new Promise((resolve, reject) => {
			this.http.post(urls.visit+'/store'+ '?token='+this.global.token(), true, data, "Guardando..")
				.then(async (res: any) => {
					resolve(res);
					//this.sugerenciaCreadaSource.next();
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
	createMovementVisit(data) {
	
		return new Promise((resolve, reject) => {
			this.http.post(urls.visit+'/storeMovement'+ '?token='+this.global.token(), true, data, "Guardando..")
				.then(async (res: any) => {
					resolve(res);
					//this.sugerenciaCreadaSource.next();
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}

	

}
