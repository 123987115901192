import { Injectable } from '@angular/core';
import { urls } from '../../environments/environment';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';
import { ActionsService } from './ActionService.service';

@Injectable({ providedIn: 'root' })
export class SuggestionService {
	business() { return JSON.parse(localStorage.getItem("business")).user_id };
	constructor(private http: HttpService, public global: ActionsService) { }

	

    viewSuggestion(data, load) {
		
		return new Promise((resolve, reject) => {
			this.http.get(urls.suggestion  + '?token='+this.global.token()+'& start='+data.start +'& limit='+data.limit+'& search='+data.search+'& type='+data.type+'& status='+data.status, load)
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
	
	detailSuggestion(id) {
		
		return new Promise((resolve, reject) => {
			this.http.get(urls.suggestion +'/'+id+ '?token='+this.global.token(), "Cargando...")
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
	createSuggestion(title, description,type,residence,archive) {
		let data = {
			name:title,
			description: description,
			archive:archive,
			type:type,
			residence:residence
		};
		return new Promise((resolve, reject) => {
			this.http.post(urls.suggestion+'/store'+ '?token='+this.global.token(), true, data, "Guardando..")
				.then(async (res: any) => {
					resolve(res);
					//this.sugerenciaCreadaSource.next();
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}

	

}
