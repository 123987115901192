import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { urls } from '../../environments/environment';
import { Subject } from 'rxjs';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class ActionsService {
	token() { return JSON.parse(localStorage.getItem("profile")).token };
	business() { return JSON.parse(localStorage.getItem("business")).user_id };
	user_data() { return JSON.parse(localStorage.getItem("profile")).user_data };
	busines() { return JSON.parse(localStorage.getItem("profile")).business_data };	
	residence() { return JSON.parse(localStorage.getItem("profile")).residences };
	social_areas() { return JSON.parse(localStorage.getItem("profile")).social_areas };
	rol() { return JSON.parse(localStorage.getItem("profile")).rol_name };


	constructor(private toastCtrl: ToastController, public alertController:AlertController, private http:HttpService) { }

    async presentAlert(title: string, message: string, confirmCallback: () => void) {
		let timeoutReached = false;

		const alert = await this.alertController.create({
		  cssClass: '',
		  mode: 'ios',
		  header: title,
		  subHeader: `¿Está seguro de ${message}?`,
	  
		  buttons: [
			{ text: 'Cancelar', role: 'cancel', cssClass: 'secondary', handler: () => {} },
			{
			  text: 'Aceptar',
			  handler: () => {
				if (!timeoutReached) {
				  confirmCallback(); // Llamada a la función de confirmación solo si no se alcanzó el timeout
				}
			  }
			},
		  ],
		});
	  
		const timeoutDuration = 15000; // 15000 milisegundos (15 segundos)
		const timeoutId = setTimeout(() => {
		  timeoutReached = true;
		  this.error(); // Llamada a this.error() si se alcanza el timeout
		  alert.dismiss(); // Oculta el loading
		}, timeoutDuration);
	  
		await alert.present();
	  
		await alert.onDidDismiss().then(() => {
		  clearTimeout(timeoutId); // Cancela el temporizador en caso de confirmación
		});
	
	  }
	  async error() {
		const alert = await this.alertController.create({ header: 'Error', mode: 'ios', message: 'El proceso se ha cancelado debido a un tiempo excedido.', buttons: ['OK'] });
		await alert.present();
	  }
    async presentToast(message: string) {
		const toast = await this.toastCtrl.create({
			message,
			duration: 1000,
			position: 'top',
		});
		toast.present();
	}
	async toast_error(message: string) {
		const toast = await this.toastCtrl.create({
			message,
			duration: 1000,
			position: 'bottom',
			color: 'danger', // Establece el color de fondo rojo
		});
		toast.present();
	}
	async toast_succes(message: string) {
		const toast = await this.toastCtrl.create({
			message,
			duration: 1000,
			position: 'top',
			color: 'success', // Establece el color de fondo rojo
		});
		toast.present();
	}
	async toast_warning(message: string) {
		const toast = await this.toastCtrl.create({
			message,
			duration: 1000,
			position: 'top',
			color: 'warning', // Establece el color de fondo rojo
		});
		toast.present();
	}

}
