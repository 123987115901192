import { Component, Input, OnInit } from '@angular/core';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { PopoverController } from '@ionic/angular';
import { ActionsService } from 'src/app/services/ActionService.service';
@Component({
  selector: 'app-view-servicio-data',
  templateUrl: './view-servicio-data.component.html',
  styleUrls: ['./view-servicio-data.component.scss'],
})
export class ViewServicioDataComponent implements OnInit {
  @Input() servicios: any;

  constructor(private callNumber: CallNumber, private service:ActionsService,private popoverController: PopoverController) { }

  ngOnInit() {}
  call(){

    
  }
  callNumbers(phoneNumber: string ){
    const title = 'Llamar';
    const message = ' llamar a '+phoneNumber;
    this.popoverController.dismiss();
    this.service.presentAlert(title, message, () => {
   this.callNumber.callNumber(phoneNumber, true)
    .then(() => console.log('Llamada exitosa!'))
    .catch(() => console.log('Error al intentar llamar'));
 
    });

    
   
}
}
