/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { QRCodeService } from 'src/app/services/QRServices.service';
import { Capacitor, Plugins,  } from "@capacitor/core";
const { FileSharer,Filesystem ,FilesystemDirectory} = Plugins;
import html2canvas from 'html2canvas';
import { Platform } from '@ionic/angular';

import { ElementRef } from '@angular/core';

@Component({
  selector: 'generate-modal-qr',
  templateUrl: './generate-qr.component.html',
  styleUrls: ['./generate-qr.component.scss'],
})
export class GenerateQRComponent implements OnInit {

  @Input() id:any;
  @Input() url:any;
  @Input() type:any;

  isWeb: boolean;
	datos = { titulo: "Código QR", menu: false, back: true }
	qrImageElement: any;
	token() { return JSON.parse(localStorage.getItem("profile")).token };
	@ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>; // Referencia al elemento canvas en tu plantilla
	modal:any
	public myAngularxQrCode: string = "";
	public qrCodeDownloadLink: SafeUrl = "";
	public qrCodeBase64: string = '';

  	constructor(
    public sanitizer: DomSanitizer,
	public loadingController:LoadingController,
	private modalController: ModalController,
	public alertController :AlertController,
	private elementRef: ElementRef,
	private qr:QRCodeService,
	private platform: Platform

  ) {
	this.modal = modalController;
	this.isWeb = !this.platform.is('cordova') && !this.platform.is('capacitor');
   }
   onChangeURLweb(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }
   onChangeURL(url: string) {

	this.qrCodeDownloadLink = url;
	fetch(url)
	  .then((response) => response.blob())
	  .then((blob) => {
		const reader = new FileReader();
		reader.onloadend = () => {
		  this.qrCodeBase64 = reader.result as string;
		};
		reader.readAsDataURL(blob);
	  })
	  .catch((error) => {
		console.error('Error al obtener el base64 del código QR:', error);
	  });
  }
  captureQRCodeAsImage() {
	// Obtén una referencia al elemento <qrcode>
	const qrcodeElement = document.querySelector('qrcode') as HTMLElement;
  
	if (qrcodeElement) {
	  // Captura el contenido del elemento <qrcode> como una imagen
	  html2canvas(qrcodeElement).then((canvas) => {
		// Convierte el canvas en una imagen en formato base64
		const qrCodeImageBase64 = canvas.toDataURL('image/png');
  
		// Ahora puedes hacer lo que quieras con la imagen base64, como guardarla o compartirla
		this.qrCodeBase64 = qrCodeImageBase64;

//this.modalController.dismiss()
if (this.type==0) {
	this.qr.compartirQRCode(this.qrCodeBase64) .then(() => {

	 // Resuelve la promesa si la compartición es exitosa
	  })
}else{
	 this.qr.fileshare(this.qrCodeBase64) .then(() => {
	this.modalController.dismiss();
 // Resuelve la promesa si la compartición es exitosa
  })
}
 
 

  
		// También puedes mostrar la imagen en tu aplicación si es necesario
		// Por ejemplo, puedes crear un elemento de imagen en tu plantilla HTML y asignarle el src
		// this.qrCodeImageElement.src = qrCodeImageBase64;
	  });
	}
  }
  
  



  obtenerIdAsincronamente(): Promise<string> {
    return new Promise<string>((resolve) => {
      // Simula una operación asincrónica (por ejemplo, una llamada a una API)
      setTimeout(() => {
        const id =  this.id.toString();
        resolve(id);
      }, 500); // Simula un retardo de 1 segundo
    });
  }
  
  ngOnInit() {
	
	if (this.type==0) {
		this.obtenerIdAsincronamente().then((id) => {
			this.myAngularxQrCode = id;
		  });
	}else{
		if (this.type==1) {
			this.obtenerIdAsincronamente().then((id) => {
				this.myAngularxQrCode = id;
			  });
			
			 
			 // Agregar un retraso de 2 segundos (2000 milisegundos) antes de ejecutar la función
// Usando bind para establecer el contexto
 setTimeout(async function() {
	if (this.type==1) {
		//console.log('es web');
	
		if (this.isWeb) {
			const loading = await this.loadingController.create({
				mode: 'ios',
				cssClass: 'my-custom-class',
				message: 'Cargando...',
			  });
			  await loading.present();

			  const timeout = setTimeout(async () => {
				loading.dismiss();
	
			  }, 5000);
			//console.log(this.qrCodeDownloadLink);
		
				
				
			
				 loading.dismiss();
				 this.downloadQRCode()
				 this.modalController.dismiss();
		}else{
					this.captureQRCodeAsImage()
		}
	
	}else{
	

	}
	

  
  }.bind(this), 1000);
  
		}
	}
  }
  public downloadQRCode() {
	// Obtén el elemento qrcode por su clase "center"
	const qrcode = document.querySelector('.center');
	
	// Asegúrate de que el elemento qrcode se haya encontrado
	if (qrcode) {
	  // Obtén el canvas dentro del elemento qrcode
	  const canvas = qrcode.querySelector('canvas') as HTMLCanvasElement;
	
	  // Asegúrate de que el canvas se haya encontrado
	  if (canvas) {
		// Exporta el contenido del canvas como una imagen
		const imageDataURL = canvas.toDataURL('image/png');
	
		// Crea un enlace para descargar la imagen
		const downloadLink = document.createElement('a');
		downloadLink.href = imageDataURL;
		downloadLink.download = 'qr_code.png';
	
		// Simula un clic en el enlace para iniciar la descarga
		downloadLink.click();
	  }
	}
	
	
	 }
}


 


