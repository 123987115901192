import { Component } from '@angular/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Plugins } from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor() {
    this.setupNotificationHandler();
    // Show the splash for two seconds and then automatically hide it:

  }
  async setupNotificationHandler() {
    LocalNotifications.addListener('localNotificationActionPerformed', async (notification) => {
      const { Alert } = Plugins;

      const alertOptions = {
        title: 'Alerta',
        message: 'Has hecho clic en la notificación',
        buttons: ['OK']
      };

      await Alert.show(alertOptions);
    });
  }
}
