import { Component, Injectable, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({ selector: 'app-footer', templateUrl: './footer.component.html', styleUrls: ['./footer.component.scss'], })

@Injectable({ providedIn: 'root' })

export class FooterComponent implements OnInit {
  @Input() datos: any = '';
  logo = 'assets/logo/1.png';
  version=environment.version
  constructor(
  ) {
  }

  ngOnInit() { }

}
