/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit,Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, PopoverController, ToastController } from '@ionic/angular';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { ActionsService } from 'src/app/services/ActionService.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.scss'],
})


export class ServiciosComponent implements OnInit {

	@Input() servicios: any;
	datos = { titulo: "Crear Servicio", menu: false, back: true }
	reservaForm: FormGroup;
  isWeb: boolean;

  	constructor(
    	private formBuilder: FormBuilder,
		private modalController: ModalController,
		private toastCtrl: ToastController,
		private popoverController: PopoverController,
		private callNumber: CallNumber, 
		private service:ActionsService,
		private sanitizer: DomSanitizer,
    private platform: Platform
  ) {
    this.isWeb = !this.platform.is('cordova') && !this.platform.is('capacitor');
   }

   ionViewDidEnter() {
	
	}


	transform(value: any): any {
		return this.sanitizer.bypassSecurityTrustHtml(value);
	  }

  ngOnInit() {}
  get descriptionHTML(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.servicios.description);
  }
  callNumbers(codigo:string, phoneNumber: string ){
    const title = 'Llamar';
    const message = ' llamar a +'+codigo+phoneNumber;
    const numero='+'+codigo+phoneNumber;
    //console.log(numero);
    
    this.popoverController.dismiss();
    this.service.presentAlert(title, message, () => {
   this.callNumber.callNumber(numero, true)
    .then(() => console.log('Llamada exitosa!'))
    .catch(() => console.log('Error al intentar llamar'));
 
    });

    
   
}





}
