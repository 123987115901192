// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionSheetController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { DebtsService } from 'src/app/services/debts.service';

import { PrintOptionsService } from 'src/app/services/printOption.service';
import { ViewComponent } from '../../modal/view.component';
import { urls } from 'src/environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
interface PaymentItem {
  residence_id: number;
  extras_data: {
    type: string;
    // Otras propiedades de extras_data
  };
  // Otras propiedades...
}
@Component({
  selector: 'app-debt',
  templateUrl: './debt.component.html',
  styleUrls: ['./debt.component.scss'],
})
export class DebtComponent implements OnInit {
  @Input() _id: number=null;
    @Input() title: string=null;


  payment : any;
  pagos :  any
  datos = { titulo:"hola", menu: false, back: true }
  defaultImage = 'https://cdn.icon-icons.com/icons2/564/PNG/512/Add_Image_icon-icons.com_54218.png'; // Ruta de la imagen por defecto (ajusta la ruta según tu estructura de archivos)
  clickedImage: string | null = null;

  avatar: any = 'https://i.ibb.co/1rBHJvp/Only-logo-House.png';
  location: any;
  residences;
  isWeb: boolean;
  busines;
  mostrarSeccion = false;
  constructor(
    private formBuilder: FormBuilder,
		private modalController: ModalController,
		private toastCtrl: ToastController,
    public debtservice:DebtsService,
    public printservice: PrintOptionsService,
    public actionSheetController: ActionSheetController,
    private sanitizer: DomSanitizer,
    private platform: Platform
  ) {
    this.isWeb = !this.platform.is('cordova') && !this.platform.is('capacitor');
   }
   
  ngOnInit() {
    this.datos={titulo: this.title, menu: false, back: true }
   this.detailSuggestion()
   this.residences = this.residence()[0].id;
   this.busines = this.business();


   
  }
  residence() { return JSON.parse(localStorage.getItem("profile")).residences };
  business() { return JSON.parse(localStorage.getItem("profile")).business_data  };
  formatTime12Hour(dateTime: string): string {
    const date = new Date(dateTime);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
  }
  async detailSuggestion(){

    this.debtservice.DetailDebts(this._id).then((res: any) => {

 this.payment=res
 this.pagos=res


 
    
    }, err => {
      console.log(err);
    });
  }

   formatDateString(dateString) {
    const date = new Date(dateString);
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }
  
  getDescriptionHTML(description: string): SafeHtml {
   
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }
  toggleSeccion() {
    // Cambiar el estado de mostrarSeccion al hacer clic en el botón
    this.mostrarSeccion = !this.mostrarSeccion;
  }
  descargarweb(servicios, positio){
    this.printservice.descargarArchivo(urls.printpayment,1,servicios,servicios.id,'debt')
  }
  async openOptionsDowloand(servicios, position) {

  
    let buttons = [
      {
        text: 'Ver documento',
        icon: 'search',
        handler: () => {
          this.printservice.presentModalDocument(urls.printpayment, servicios.id, ViewComponent,'debts');
        }
      },
      {
        text: 'Descargar documento',
        icon: 'download',
        handler: () => {
          this.printservice.descargar(urls.printpayment, 1, servicios, servicios.id,'debt');
        }
      },
      {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel',
        handler: () => {}
      }
    ];
  
    if (!this.isWeb) {
      buttons.splice(2, 0, {
        text: 'Compartir documento',
        icon: 'shuffle',
        handler: () => {
          this.printservice.descargar(urls.printpayment, 2, servicios, servicios.id,'debt');
        }
      });
    }
  
    const actionSheet = await this.actionSheetController.create({
      header: 'Opciones impresión ',
      cssClass: 'my-custom-class',
      buttons: buttons
    });
  
    await actionSheet.present();
  }
  


  //  ionViewDidEnter() {
	// 	console.log(this.reserva_id);
	// 	if (this.reserva_id == null) {
	// 		this.datos.titulo = 'Nueva Reserva';
	// 	} else {
	// 		this.datos.titulo = 'Actualizar reserva';
	// 		this.reservaForm.get('id').setValue(this.reserva_id);
	// 		this.lookForClient(this.reserva_id);
	// 	}
	// }







  customerForm(data) {

		this.modalController.dismiss();
	}





}
