export const type = [
    {
      id: 1,
      titulo: 'SIMPLE',
      identification: 'SIMPLE'

    },
    {
        id: 2,
        titulo: 'AVANZADO',
        identification: 'ADVANCE'
      },
  ];
  