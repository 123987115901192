import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators,FormBuilder } from '@angular/forms';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { ModalController, PopoverController } from '@ionic/angular';
import { ActionsService } from 'src/app/services/ActionService.service';
import { VisitComponent } from '../../form/visit/visit.component';
@Component({
  selector: 'app-view-observation-data',
  templateUrl: './view-observation-data.component.html',
  styleUrls: ['./view-observation-data.component.scss'],
})
export class ViewObsercationDataComponent implements OnInit {
  @Input() servicios: any;
  myForm: FormGroup;
  constructor(	public modalController: PopoverController,private formBuilder: FormBuilder,private service:ActionsService,private popoverController: PopoverController) {
    this.myForm = this.formBuilder.group({
      description: ['', Validators.required],
    });
   }

  ngOnInit() {}
  call(){

    
  }
  config = {
    placeholder: 'Ingrese una observación (opcional)',
    tabsize: 2,
    height: '170px',

  }
    
  onSubmit() {
    if (this.myForm.valid) {
      // Aquí puedes realizar la acción de enviar el formulario.
     
      this.modalController.dismiss(this.myForm.value)

    }
  }
}
