import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() isRequired: boolean=false;
  @Input() max: string = '';
  @Input() type: string = 'text';
  focused: boolean = false;
  hasValue: boolean = false;
  @Input()  Control = new FormControl('');
  @Input() isDisabled: boolean; // Agrega la propiedad isDisabled


  onBlur(event: any) {
    this.focused = false;
    this.hasValue = this.Control.value !== '';
    this.onTouched();
  }

  // Métodos requeridos para implementar ControlValueAccessor
  writeValue(value: any) {
    this.Control.setValue(value);
  }
 
  registerOnChange(fn: any) {
    this.Control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.Control.disable();
    } else {
      this.Control.enable();
    }
  }

  private onTouched: () => void = () => {};
}
