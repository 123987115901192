
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { es } from 'date-fns/locale';
import {
  AlertController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { format, parseISO } from 'date-fns';
import { SheetComponent } from '../../sheet-modal/sheet.component';
import { ReservaService } from 'src/app/services/reserva.service';
import { ActionsService } from 'src/app/services/ActionService.service';

@Component({
  selector: 'app-reservas',
  templateUrl: './reservas.component.html',
  styleUrls: ['./reservas.component.scss'],
})
export class ReservasComponent implements OnInit {
  @Input() _id: number = null;
  @Input() type: number = null;
  @Input() title: string = '';
  @Input() establishments: { id: number, extras_data: { numero: number } }[] = [];


  selectedDate: Date;
  minDate: string;
  datos = {};
  valor: number = 0.0;
  resultado: number = 0.0;
  availableHours: string[] = [];
  selectedHours: { index: number; hour: string; end: string }[] = [];
  // establishments:any[] = [];
  selectedButtons: boolean[] = [];
  maxSelected = 2;
  disableSubmitButton = false;
  hours = [];
  taxes = [];
  selectedMode = 'date';
  selectedValue: any;
  showPicker = false;
  showPickerTime = false;
  showPickerTime2 = false;
  dateValue = format(new Date(), 'yyyy-MM-dd');
  formattedSString = '';

  start: string = ''; // Declaración inicial para la hora de inicio seleccionada
  end: string = ''; // Declaración inicial para la hora de fin seleccionada
  positionStart: number = 0; // Declaración inicial para la posición de inicio
  positionEnd: number = 0; // Declaración inicial para la posición de fin
  selectionType: number = 1; // Declaración inicial para el tipo de selección (1 o 2)
  rangePrice: number = 0; // Precio por rango de horas
  total: string = '0'; // Total calculado
  timeSelectionError: string;
  disableButtons: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private toastCtrl: ToastController,
    public reserva: ReservaService,

    private alertService: ActionsService
  ) {
    this.createForm();
  }

  toggleButtonSelection(index: number) {


    const selectedHour = this.hours[index];

    if (
      this.selectedHours.length === 1 &&
      this.selectedHours[0].index === index
    ) {
      // Si ya hay una hora seleccionada y es la misma que se hizo clic, deselecciona
      this.selectedHours = [];
    } else {
      this.selectedHours = [
        { index, hour: selectedHour.start, end: selectedHour.end },
      ];
    }

    this.validateTimeSelection();
  }

  validateTimeSelection() {
    const selectedCount = this.selectedHours.length;

    if (selectedCount !== 1) {
      this.timeSelectionError = 'Selecciona una hora';
  
      this.resultado = 0.0;
      this.disableSubmitButton = true; // Deshabilita el botón de enviar
    } else {
      this.timeSelectionError = '';

      // Construir las fechas en el formato deseado
      const selectedHour = this.selectedHours[0].hour;
      const formattedDate = `${this.selectedDate} ${selectedHour}:00`;

     
      this.valor = this.selectedValue?.hour_price;
      let total = this.taxes.reduce((acc, tax) => acc + (this.valor * tax.tax_value), 0);
      let tax = total / 100;
      console.log(  this.valor);
      
      let numero1: number = Number( this.valor) ;
      let numero2: number =Number( tax);
      
      this.resultado = numero1 + numero2;
      this.disableSubmitButton = false; // Habilita el botón de enviar
    }
  }

  isButtonSelected(index: number): boolean {
    return this.selectedHours.some((hourObj) => hourObj.index === index);
  }



  dateChanged(value) {


    const parsedDate = parseISO(value.detail.value);
    const formattedDate = format(new Date(parsedDate), 'yyyy-MM-dd');
    if (!this.selectedValue) {
      this.alertService.toast_error('Seleccione un área social primero');
     
    } else {
      let data = {
        social: this.selectedValue.id,
        date: formattedDate,
      };
      //console.log(data);
      
      this.reserva
        .GetHours(data, 'Cargando..')


        .then(
          (data: any) => {
  // console.log(data);
     // Si ya hay una hora seleccionada y es la misma que se hizo clic, deselecciona
     this.selectedHours = [];
            data = data;
            this.availableHours = data;
            this.hours = data;
          },
          (error) => {
            if (error.error.error.status == 405) {
            } else if (error.error.error.status == 401) {
              localStorage.clear();
            } else {
              //this.presentToast(error.message)
            }
          }
        );
    }
    this.formattedSString = formattedDate;

  }
  onSelectedData(data: any) {

    this.reservaForm.get('area').setValue(data.name); // Actualizar el valor del campo
    this.selectedValue = data; // Capturar el dato seleccionado
    this.hours = [];
    this.valor = 0.0;
    this.disableSubmitButton = true;
    this.availableHours = [];

    if (this.formattedSString !== '') {
      const formData = this.reservaForm.value;
      const isoDate = formData.selectedDate;

      // Llama a dateChanged con la fecha
      this.dateChanged({ detail: { value: isoDate } });
    }
  }
                                                      
  publicacion = {
    titulo: '',
    descripcion: '',
    imagen: '',
  };

  reservaForm: FormGroup;

  ionViewDidEnter() {
    this.establishments = this.reserva.establishments();

    const currentDate = new Date();
    const ecuadorTimezoneOffset = -5; // Ecuador's timezone offset in                                    hours
    currentDate.setHours(currentDate.getHours() + ecuadorTimezoneOffset); // Adjust for Ecuador's timezone
    this.minDate = currentDate.toISOString();


    this.type_modal();
  }
  config = {
    placeholder: 'Ingrese una observación (opcional)',
    tabsize: 2,
    height: '170px',

  }
  async type_modal() {


    if (this.type == 1) {
      //    this.detailSuggestion();
      // console.log(this.post);

      this.datos = { titulo: 'Detalle Publicacion', menu: false, back: true };
    } else {
      this.reservaForm = this.formBuilder.group({
        name: new FormControl({
          value: this.reserva.establishments()[0].id,
          disabled: false,
        }),
        selectedDate: ['', Validators.required],
        area: ['', Validators.required],
        observation: [''],
        selectedHours: [[]], // Un arreglo para almacenar las horas seleccionadas
        // ... Otros campos ...
      });
      this.datos = { titulo: 'Crear Reserva', menu: false, back: true };
      this.reservaForm.get('name');
    
    }
  }

  createForm() {
    this.reservaForm = this.formBuilder.group({
      name: new FormControl({
        value: this.reserva.establishments()[0].id,
        disabled: false,
      }), // Empieza habilitado por defecto
      area: new FormControl('Seleccione una area social'),
      observation: [''],
      selectedDate: [''],
    });
    this.reservaForm.get('name')?.touched;

    if (this.establishments.length == 0) {
      // this.reservaForm.get('name')?.disable(); // Deshabilita el FormControl si hay un solo elemento
      this.reservaForm
        .get('name')
        ?.setValue(this.reserva.establishments()[0].id);

    }
  }

  ngOnInit() {
    this.reserva.viewTaxes().then(
      (res: any) => {
      //console.log(res);
        this.taxes=res
      },
      (err) => {
        console.log(err);
      }
      );
  }
  formatDateDetails(apiStart, apiEnd) {
    const start = new Date(apiStart);
    const end = new Date(apiEnd);
  
    const startFormatted = start.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).replace(/(\d+)\/(\d+)\/(\d+), (\d+:\d+ [APMapm]+)/, '$3-$1-$2 $4');
    
    const endFormatted = end.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  //console.log(startFormatted);
  
    return `${startFormatted} - ${endFormatted}`;
  }
  
  async postReserva(data) {

    
    const title = this.title;
    const message = this.title;
    const formData = this.reservaForm.value;                                                        

    const selectedDate = formData.selectedDate;
    const dateObj = new Date(selectedDate);
    
    // Ajustar la zona horaria a Ecuador (UTC-5)
    dateObj.setUTCHours(dateObj.getUTCHours() - 5);
    
    // Obtener la fecha en el formato deseado (solo fecha)
    const formattedDate = dateObj.toISOString().split('T')[0];
    

    const hourend =
      formattedDate + ' ' + this.formatTime(this.selectedHours[0].end);
    const houstart =
      formattedDate + ' ' + this.formatTime(this.selectedHours[0].hour);

    let datas = {
      establishment_id: formData.name,
      total: this.valor,
      date: formattedDate,
      start: houstart,
      end: hourend,
      observation:  this.reservaForm.get('observation').value,
      social_area_id: this.selectedValue.id,
    };


    this.alertService.presentAlert(title, message, () => {
      this.reserva.PostsRent(datas).then(
        (responseData: any) => {

          
          const formattedDate = new Date(responseData.created_at)
            .toISOString()
            .split('T')[0];
          const end = responseData.extras_data.end;

          const dateDetails = this.formatDateDetails(
            responseData.extras_data.start,
            responseData.extras_data.end
          );
          const formattedData = {
            balance: responseData.totals.balance,
            created_at: formattedDate,
            date_details: dateDetails,
            id: responseData.id,
            load: false,
            paid: responseData.totals.paid,
            residence_id: responseData.extras_data.social_area_id,
            total: responseData.totals.total,
            type: responseData.extras_data.social_area,
          };
     // console.log(formattedData);
      

      this.alertService.toast_succes('Dato guardado con exito');
          this.modalController.dismiss(formattedData);
        },
        (err) => {}
      );
    
      // Agrega aquí la lógica que deseas ejecutar al confirmar la alerta
    });

    //this.modalController.dismiss();
  }
  formatTime(timeString) {
    const timeParts = timeString.split(' ');
    const [time, period] = timeParts[0].split(':');

    let formattedTime = time;
    if (period === 'PM' && time !== '12') {
      formattedTime = String(Number(time) + 12);
    }

    return `${formattedTime}:00:00`;
  }



  async openSearchSheet() {
    const modal = await this.modalController.create({
      component: SheetComponent,
      componentProps: {
        // Pasa las propiedades que necesitas para tu modal si es necesario
      },
    });

    await modal.present();

    // Espera a que el modal se cierre para ejecutar alguna acción después de que se cierre el modal
    const { data } = await modal.onWillDismiss();
    //console.log(data); // Puedes acceder a los datos enviados desde el modal si los hay
  }
}
