export const guest_type = [
    {
      id: 1,
      titulo: 'Visita',
      identification: 'VISIT'

    },
    {
        id: 2,
        titulo: 'Proveedor',
        identification: 'SUPPLIER'
      },
      {
        id: 2,
        titulo: 'Arrendatario Temporal',
        identification: 'TEMPORARY LESSEE'
      },
      {
        id: 2,
        titulo: 'Otro',
        identification: 'OTHER'
      },
  ];
  