// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionSheetController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { DebtsService } from 'src/app/services/debts.service';

import { PrintOptionsService } from 'src/app/services/printOption.service';
import { ViewComponent } from '../../modal/view.component';
import { urls } from 'src/environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IcomesService } from 'src/app/services/incomes.service';
import { Chart } from 'chart.js';
interface PaymentItem {
  residence_id: number;
  extras_data: {
    type: string;
    // Otras propiedades de extras_data
  };
  // Otras propiedades...
}
@Component({
  selector: 'app-bill-component',
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.scss'],
})
export class BillComponent implements OnInit {
  @Input() _id: number=null;
    @Input() title: string=null;


  payment : any;
  pagos :  any
  datos = { titulo:"hola", menu: false, back: true }
  clickedImage: string | null = null;


  location: any;
  residences;
  isWeb: boolean;
  busines;
  mostrarSeccion = false;

  constructor(
    private formBuilder: FormBuilder,
		private modalController: ModalController,
		private toastCtrl: ToastController,
    public incomeservice:IcomesService,
    public printservice: PrintOptionsService,
    public actionSheetController: ActionSheetController,
    private sanitizer: DomSanitizer,
    private platform: Platform
  ) {
    this.isWeb = !this.platform.is('cordova') && !this.platform.is('capacitor');
   }
   
  ngOnInit() {
    this.datos={titulo: this.title, menu: false, back: true }
   this.detailSuggestion()
   this.residences = this.residence()[0].id;
   this.busines = this.business();


   
  }
  residence() { return JSON.parse(localStorage.getItem("profile")).residences };
  business() { return JSON.parse(localStorage.getItem("profile")).business_data  };
  formatTime12Hour(dateTime: string): string {
    const date = new Date(dateTime);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
  }
  async detailSuggestion(){

    this.incomeservice.DetailIcome(111).then((res: any) => {

 this.payment=res
 this.pagos=res

//console.log(res);

 
    
    }, err => {
      console.log(err);
    });
  }

   formatDateString(dateString) {
    const date = new Date(dateString);
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }
  
  getDescriptionHTML(description: string): SafeHtml {
   
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }
  toggleSeccion() {
    // Cambiar el estado de mostrarSeccion al hacer clic en el botón
    this.mostrarSeccion = !this.mostrarSeccion;
  }
  descargarweb(servicios, positio){
    this.printservice.descargarArchivo(urls.printpayment,1,servicios,servicios.id)
  }

  










  customerForm(data) {

		this.modalController.dismiss();
	}





}
