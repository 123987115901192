import { politic } from './../../../environments/environment';
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';



@Component({
  selector: 'app-term',
  templateUrl: './term.component.html',
  styleUrls: ['./term.component.scss'],
})
export class TermComponent implements OnInit {

  @Input() id:any;
  @Input() url:any;
  modal:any
	datos = { titulo: "Términos y Condiciones", menu: false, back: false }

	pdf;


  	constructor(
    public sanitizer: DomSanitizer,
	public loadingController:LoadingController,
	public alertController :AlertController,
  private modalController: ModalController,
  ) {
    this.modal = modalController;
   }

  

  

  ngOnInit() {this.mostrar();}


  async mostrar() {


	
    this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(politic);



   
  }

  async error() {
    const alert = await this.alertController.create({ header: 'Error', mode: 'ios', message: 'No se pudo cargar el PDF, intente nuevamente.', buttons: ['OK'] });
    await alert.present();
  }
}


 


