/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import {
	ScannerQRCodeConfig,
	ScannerQRCodeResult,
	NgxScannerQrcodeService,
	NgxScannerQrcodeComponent,
	ScannerQRCodeSelectedFiles,
  } from 'ngx-scanner-qrcode';
  import { Platform } from '@ionic/angular';
import { VisitComponent } from '../form/visit/visit.component';


@Component({
  selector: 'app-modal-qr',
  templateUrl: './modal-qr.component.html',
  styleUrls: ['./modal-qr.component.scss'],
})
export class ModalQRComponent implements OnInit {

  @Input() id:any;
  @Input() url:any;
  isWeb: boolean;
	datos = { titulo: "Escanear QR", menu: false, back: false }
	token() { return JSON.parse(localStorage.getItem("profile")).token };
	public config: ScannerQRCodeConfig = {
		constraints: {
		  video: {
			width: window.innerWidth
		  },
		},
		// canvasStyles: [
		//   { /* layer */
		//     lineWidth: 1,
		//     fillStyle: '#00950685',
		//     strokeStyle: '#00950685',
		//   },
		//   { /* text */
		//     font: '17px serif',
		//     fillStyle: '#ff0000',
		//     strokeStyle: '#ff0000',
		//   }
		// ],
	  };
	pdf;


  	constructor(
    public sanitizer: DomSanitizer,
	public loadingController:LoadingController,
	public alertController :AlertController,
	public modalController: ModalController,
  private qrcode: NgxScannerQrcodeService,
  private platform: Platform
  ) {
	this.isWeb = !this.platform.is('cordova') && !this.platform.is('capacitor');
   }

   public qrCodeResult: ScannerQRCodeSelectedFiles[] = [];
	  public qrCodeResult2: ScannerQRCodeSelectedFiles[] = [];
	
	  @ViewChild('action') action!: NgxScannerQrcodeComponent;
	  ngAfterViewInit(): void {
		this.action.isReady.subscribe((res: any) => {
		   this.handle(this.action, 'start');
		});
	  }
	
	  public onEvent(e: ScannerQRCodeResult[], action?: any): void {
		// e && action && action.pause();
		//console.log(e);
		//alert(e[0].value)
		this.detailvisit(e[0].value);
	  }
	  async detailvisit (visit_id) {

		const modal = await this.modalController.create({
		  component: VisitComponent,
		  componentProps: {
			_id: visit_id,
			type: 1,
			title: 'Detalle Visitas',
		  },
		});
		modal.onWillDismiss().then((res) => {});
		return await modal.present();
	  }
	  public handle(action: any, fn: string): void {
		const playBackFacingCamera = (devices: any[]) => {
			// Filtrar cámaras por orientación y tipo
			const filteredCameras = devices.filter((device) => {
			  return (
				// Verificar si la cámara es trasera por su orientación
				(device.kind === 'videoinput' &&
				  device.label &&
				  /back|rear|environment/gi.test(device.label)) ||
				// O verificar por la propiedad 'facingMode' si está disponible
				(device.kind === 'videoinput' &&
				  device.getCapabilities &&
				  device.getCapabilities().facingMode === 'environment')
			  );
			});
		  
			// Seleccionar la primera cámara trasera encontrada o la primera disponible
			const selectedCamera = filteredCameras.length > 0 ? filteredCameras[0] : devices[0];
		  
			// Reproducir la cámara seleccionada
			action.playDevice(selectedCamera.deviceId);
		  
			// Actualizar el índice de la cámara activa en el componente
			action.deviceIndexActive = devices.findIndex((device) => device.deviceId === selectedCamera.deviceId);
		  
			// Activar la bool si se va por el device 0
			action.activateSelect = selectedCamera === devices[0];
		  };
		  
		  // Llamar a la función dependiendo de la condición
		  if (fn === 'start') {
			action[fn](playBackFacingCamera).subscribe((r: any) => console.log(fn, r), alert);
		  } else {
			action[fn]().subscribe((r: any) => console.log(fn, r), alert);
		  }
		  
	  }
	
	  public onDowload(action: NgxScannerQrcodeComponent) {
		action.download().subscribe(console.log, alert);
	  }
	
	  public onSelects(files: any) {
		this.qrcode.loadFiles(files, 0.5).subscribe((res: ScannerQRCodeSelectedFiles[]) => {
		  this.qrCodeResult = res;
		});
	  }
	
	  public onSelects2(files: any) {
		this.qrcode.loadFilesToScan(files, this.config, 0.5).subscribe((res: ScannerQRCodeSelectedFiles[]) => {
	
		  this.qrCodeResult2 = res;
		});
	  }	

    startCamera() {
      // Inicia la cámara cuando se llama a este método
  
	  if (this.action) {
		this.action.start().subscribe(
		  (result: any) => {
			// Lógica de éxito
		  },
		  (error: any) => {
			console.error('Error al iniciar la cámara', error);
		  }
		);
	  }
	  
    }
	stopCamera() {
		// Inicia la cámara cuando se llama a este método
	
		
		this.action.stop().subscribe(
		  (result: any) => {
		  
		  },
		  (error: any) => {
			console.error('Error al iniciar la cámara', error);
		  }
		);
	  }
 cerrar(){
this.stopCamera()
this.modalController.dismiss()
 }
  ngOnInit() {
    this.startCamera(); 
  }
}


 


