import { Injectable } from '@angular/core';
import { urls } from '../../environments/environment';
import { HttpService } from './http.service';
import { ToastController } from '@ionic/angular';
import { ActionsService } from './ActionService.service';

@Injectable({ providedIn: 'root' })
export class ReservaService {
ç
  residence() { return JSON.parse(localStorage.getItem("profile")).residences };  
  users() { return JSON.parse(localStorage.getItem("profile")).users };  
  establishments() { return JSON.parse(localStorage.getItem("profile")).establishments };

	constructor(private http: HttpService,	public global :ActionsService) { }

	



  viewReserva(data, load) {
		
		return new Promise((resolve, reject) => {

			this.http.get(urls.debts  + '?token='+this.global.token()+'& start='+data.start +'& limit='+data.limit+'& search='+data.search+'& status_paid='+data.status+'& type='+'social_area', load)
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
	viewTaxes( ) {
		
		return new Promise((resolve, reject) => {

			this.http.get(urls.taxes +'?token='+this.global.token(), '')
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
   
   
    


	  GetHours(data, load) {
		
		
		return new Promise((resolve, reject) => {
			this.http.get(urls.hours + '?token='+this.global.token()+'& social_area_id='+data.social+'& date='+data.date, load)
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
	PostsRent(datas) {
		let data = {
			establishment_id:datas.establishment_id,
			total: datas.total,
            date:datas.date,
			start:datas.start,
			end:datas.end,
			observation:datas.observation,
			social_area_id:datas.social_area_id,
		};
		return new Promise((resolve, reject) => {
			this.http.post(urls.storeRent+ '?token='+this.global.token(), true, data, "Guardando..")
				.then(async (res: any) => {
					resolve(res);
				
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}

}
