import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({ selector: 'app-view-business', templateUrl: './view-business.component.html', styleUrls: ['./view-business.component.css'] })

export class ViewBusinessComponent implements OnInit {
  @Input() bussiness: any;
  @Input() type: number = null;
  constructor(
    private popoverController: PopoverController
  ) { }

  ngOnInit() {
  }

  select(item) {
    this.popoverController.dismiss(item);
  }

}
