import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ModalController,
  NavController,
  ToastController,
} from '@ionic/angular';

import { SuggestionService } from 'src/app/services/suggestion.service';
import { ActionsService } from 'src/app/services/ActionService.service';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { TRANSLATIONS_ES } from '../../../../assets/js/traducciones'; 
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ReservaService } from 'src/app/services/reserva.service';


@Component({
  selector: 'app-sugerencias',
  templateUrl: './sugerencias.component.html',
  styleUrls: ['./sugerencias.component.scss'],
})
export class SugerenciasComponent implements OnInit {
  @Input() _id: number = null;
  @Input() type: number = null;
  @Input() title: string = '';
  @Input() archive: string = '';
  @Input() establishments: { id: number, extras_data: { numero: number } }[] = [];

  miFormulario: FormGroup;
  miFormularioCreate: FormGroup;
  miFormularioEdit: FormGroup;
  focused: boolean = false;
  suggestions :any;
  logo = 'assets/logo/3.png';
  datos = { titulo: 'hola', menu: false, back: true };
  location: any;
  clickedImage;

  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    public suggestion: SuggestionService,
    public navCtrl: NavController,
    public presentToast: ActionsService,
    private sanitizer: DomSanitizer,
    public reserva: ReservaService,
  ) {}
  getDescriptionHTML(description: string): SafeHtml {
   
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }
  onBlur(event: any) {
    this.focused = false;

    this.onTouched();
  }
  config = {
    placeholder: 'Ingrese una descipción',
    tabsize: 2,
    height: '170px',
       toolbar: [
        ['misc', ['codeview', 'undo', 'redo']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']],
        ['para', ['style', 'ul', 'ol', 'paragraph', 'height']]
    ],

  }
  private onTouched: () => void = () => {};
  takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Prompt,
      saveToGallery: true,
      promptLabelHeader: TRANSLATIONS_ES.camera.label,
      promptLabelPhoto: TRANSLATIONS_ES.camera.gallery,
      promptLabelPicture: TRANSLATIONS_ES.camera.takePhoto,
    });
    var imageUrl = image.dataUrl;

    // Can be set to the src of an image now
    this.clickedImage = imageUrl;
  };
 
  ngOnInit() {
    this.establishments = this.reserva.residence();

    this.miFormularioCreate = this.formBuilder.group({
      title: ['', Validators.required],
      residence: new FormControl({
        value: this.reserva.residence()[0].id,
        disabled: false,
      }),
      description: ['', Validators.required],
      types: ['incidence', [Validators.required]],
    });

    this.miFormularioEdit = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      types: ['', [Validators.required]],
    });
    this.type_modal();
  }

  async type_modal() {


    if (this.type == 1) {
      this.detailSuggestion();


      this.miFormulario = this.formBuilder.group({
        // Otros campos del formulario
        title: [{ value: '', disabled: true }], // Bloquear el campo 'title'
        description: [{ value: '', disabled: true }], // Bloquear el campo 'title'
        types: [{ value: '', disabled: true }], // Bloquear el campo 'title'
      });
      this.datos={titulo: "Detalle Asuntos", menu: false, back: true }
    } else {
      this.miFormulario = this.formBuilder.group({
        title: ['', Validators.required],
        description: ['', [Validators.required]],
        types: ['incidence', [Validators.required]],
        residence: new FormControl({
          value: this.reserva.residence()[0].id,
          disabled: false,
        }),
      });
      this.datos={titulo: "Crear Asunto", menu: false, back: true }
    }
  }
  async detailSuggestion() {
    this.suggestion.detailSuggestion(this._id).then(
      (res: any) => {
      // console.log(res);
        this.suggestions=res
      },
      (err) => {
        console.log(err);
      }
    );
  }
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('es', options);
  }
  formatDateAnswer(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true, // Si deseas mostrar el tiempo en formato de 12 horas (AM/PM)
      
    };
    return date.toLocaleDateString('es', options);
  }
    guardarFormularioCreate() {
    const title = this.title;
    const message = this.title;

    this.presentToast.presentAlert(title, message, () => {

      this.storeIssues();
    });
  }
  async storeIssues(){
    //console.log(this.miFormularioCreate);
    
    let titulo = this.miFormularioCreate.get('title').value;
    let type = this.miFormularioCreate.get('types').value;
    let description = this.miFormularioCreate.get('description').value;
    let residence = this.miFormularioCreate.get('residence').value;
   // console.log(residence);
    
    this.suggestion
      .createSuggestion(titulo, description,type,residence, this.clickedImage)
      .then(
        (res: any) => {
          this.presentToast.toast_succes('Dato guardado con exito');
          this.modalController.dismiss(res);
          this.navCtrl.navigateForward('/menu/sugerencias', {
            replaceUrl: true,
          });
        },
        (err) => {
          this.presentToast.toast_error(err.message);
        }
      );
  }
  reservaForm: FormGroup;

  lookForClient(reserva_id) {
  
  }

  customerForm(data) {

    this.modalController.dismiss();
  }




}
