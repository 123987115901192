import { Injectable } from '@angular/core';
import { urls } from '../../environments/environment';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';
import { ActionsService } from './ActionService.service';

@Injectable({ providedIn: 'root' })
export class IcomesService {
	residence() { return JSON.parse(localStorage.getItem("profile")).residences };
	business() { return JSON.parse(localStorage.getItem("business")).user_id };
	private sugerenciaCreadaSource = new Subject<void>();
	sugerenciaCreada$ = this.sugerenciaCreadaSource.asObservable();
	constructor(private http: HttpService, public global:ActionsService) { }

	

    viewincomes(data, load) {
		
		return new Promise((resolve, reject) => {
		
		
			this.http.get(urls.incomes  + '?token='+this.global.token()+'& start='+data.start +'& limit='+data.limit+'& start_date='+data.first+'& end_date='+data.end+'& type='+data.type, load)
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
	
	DetailIcome(data) {
		
		return new Promise((resolve, reject) => {
	
			
			this.http.get(urls.incomes+'/' +data + '?token='+this.global.token(), "Cargando...")
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}

	

}
