import { Component, EventEmitter, Input, OnInit,Output,ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormArray ,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ModalController,
  NavController,
  ToastController,
} from '@ionic/angular';
import { es } from 'date-fns/locale';
import { format, parseISO } from 'date-fns';

import { VisitService } from 'src/app/services/visit.service';
import { ActionsService } from 'src/app/services/ActionService.service';
import { guest_type } from 'src/assets/js/guest_type';
import { type } from 'src/assets/js/type_visit';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IonAccordionGroup } from '@ionic/angular';

@Component({
  selector: 'app-guadian-visit',
  templateUrl: './guardian-visit.component.html',
  styleUrls: ['./guardian-visit.component.scss'],
})
export class GuardianVisitComponent implements OnInit {
  @ViewChild('accordionGroup', { static: true }) accordionGroup: IonAccordionGroup;

  @Input() data: any;
  @Output() dataChange = new EventEmitter<any>();
  
  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    public visit: VisitService,
    public navCtrl: NavController,
    public presentToast: ActionsService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer
  ) {

}
 
 // Función para mostrar u ocultar un elemento según su índice

 guardarDatos() {
  // Aquí puedes agregar lógica adicional si es necesario antes de emitir los datos
  this.dataChange.emit(this.data);
}


  ngOnInit() {
  
  }


}
