/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActionsService } from 'src/app/services/ActionService.service';


@Component({
  selector: 'app-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.scss'],
})
export class SheetComponent implements OnInit {
  social_area = []; // Inicializa el arreglo
  filteredResults = []; 
  @Output() selectedData = new EventEmitter<any>(); // Nueva emisión de evento

  @Input() showModal: boolean;




  	constructor(
 public actionsService:ActionsService,
 private modalController: ModalController,
  ) {
  
   }

   ngOnInit() {
    this.social_area = this.actionsService.social_areas();
      this.filteredResults = this.social_area; // Mostrar todos los resultados al inicio
    }
    
  
    Result(data){

      this.modalController.dismiss(data);
      setTimeout(() => {

      this.selectedData.emit(data);
      }, 200);
    }
    
  
    searchByName(event: any) {
      const query = event.target.value.toLowerCase();
      this.filteredResults = this.searchSocialAreas(query);
    }
  
    searchSocialAreas(query: string) {
      return this.social_area.filter(area => area.name.toLowerCase().includes(query));
    }
  }



  



 



