/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';



@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnInit {

  @Input() id:any;
  @Input() url:any;
  modal:any
	datos = { titulo: "Visualizar Documento", menu: false, back: true }
	token() { return JSON.parse(localStorage.getItem("profile")).token };

	pdf;


  	constructor(
    public sanitizer: DomSanitizer,
	public loadingController:LoadingController,
	public alertController :AlertController,
  private modalController: ModalController,
  ) {
    this.modal = modalController;
   }

  

  

  ngOnInit() {this.mostrar();}


  async mostrar() {
	const pdfUrl = `${this.url}?token=${this.token()}`;

	//console.log(pdfUrl);
  this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/gview?embedded=true&url=${pdfUrl}`);
  //this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(pdfUrl );
    const loading = await this.loadingController.create({ cssClass: "my-custom-class", message: "Cargando...", mode: "ios", });

    await loading.present();

    const timeout = setTimeout(async () => {
      loading.dismiss();
      this.error();
    }, 15000);

    const iframe = document.getElementById("myiframe") as HTMLIFrameElement;
    iframe.addEventListener("load", () => {
      clearTimeout(timeout);
      loading.dismiss();
    });
    iframe.addEventListener("error", async () => {
      clearTimeout(timeout);
      loading.dismiss();
      this.error();
    });
  }

  async error() {
    const alert = await this.alertController.create({ header: 'Error', mode: 'ios', message: 'No se pudo cargar el PDF, intente nuevamente.', buttons: ['OK'] });
    await alert.present();
  }
}


 


