import { PollsPageModule } from './pages/polls/polls.module';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then(m => m.MenuPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'reservas',
    loadChildren: () => import('./pages/reservas/reservas.module').then(m => m.ReservasPageModule)
  },
  {
    path: 'publicaciones',
    loadChildren: () => import('./pages/publicaciones/publicaciones.module').then(m => m.PublicacionesPageModule)
  },
  {
    path: 'sugerencias',
    loadChildren: () => import('./pages/sugerencias/sugerencias.module').then(m => m.SugerenciasPageModule)
  },
  {
    path: 'notificaciones',
    loadChildren: () => import('./notificaciones/notificaciones.module').then(m => m.NotificacionesPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./pages/perfil/perfil.module').then(m => m.PerfilPageModule)
  },
  {
    path: 'debts',
    loadChildren: () => import('./pages/debts/debts.module').then( m => m.DebtsPageModule)
  },

  {
    path: 'polls',
    loadChildren: () => import('./pages/polls/polls.module').then( m => m.PollsPageModule)
  },
  {
    path: 'visit',
    loadChildren: () => import('./pages/visit/visit.module').then( m => m.VisitPageModule)
  },
  {
    path: 'binnacle',
    loadChildren: () => import('./pages/bitacora/bitacora.module').then( m => m.BitacoraPageModule)
  },
  {
    path: 'guardian-visit',
    loadChildren: () => import('./pages/guardian-visit/guardian-visit.module').then( m => m.GuardianVisitPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
