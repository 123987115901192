import { Injectable } from '@angular/core';

import { LoadingController,AlertController, ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { ActionsService } from './ActionService.service';
import { Capacitor, Plugins,  } from "@capacitor/core";
const { FileSharer,Filesystem ,FilesystemDirectory} = Plugins;

@Injectable({
  providedIn: 'root',
})
export class QRCodeService {
  constructor(

    private loadingController: LoadingController,
    private http: HttpClient,
    private global: ActionsService,
    private modalController: ModalController,
    private alertController: AlertController
  ) {}

  async compartirQRCode(url: string) {
    return new Promise<void>(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: "Compartir",
        mode: "ios",
        header: "Compartir",
        subHeader: '¿Está seguro de ' + "compartir" + ' el archivo?',
        buttons: [
          { 
            text: "Cancelar", 
            role: "cancel", 
            cssClass: "secondary", 
            handler: (blah) => { 
              // Manejar el rechazo de la promesa (por ejemplo, rechazarla con un mensaje)
              reject("Compartir cancelado");
            },
          },
          { 
            text: "Aceptar", 
            handler: () => { 
              // Realizar la compartición del archivo
              this.fileshare(url)
              alert.dismiss();
                  resolve(); // Resuelve la promesa si la compartición es exitosa
               
            },
          },
        ],
      });
  
      await alert.present();
    });
  }
  
async fileshare(url) {
  return new Promise<void>(async (resolve, reject) => {
    const loading = await this.loadingController.create({
      mode: 'ios',
      cssClass: 'my-custom-class',
      message: 'Cargando...',
    });
    await loading.present();
    resolve();
    Plugins.FileSharer.share({
      filename: "qr.png",
      contentType: "image/png",
      base64Data: url.replace('data:image/png;base64', '').replace('image/png;base64', ''),
      path: "/file.png",
    }).then((res) => {
      loading.dismiss();

      resolve(); // Resuelve la promesa si la compartición de archivos tiene éxito
    }).catch(error => {
      loading.dismiss();
      console.error("File sharing failed", error.message);
      reject(error); // Rechaza la promesa si hay un error en la compartición de archivos
    });
  });
}



}
