import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ModalController,
  NavController,
  ToastController,
} from '@ionic/angular';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { PostsService } from 'src/app/services/posts.service';
import { TRANSLATIONS_ES } from '../../../../assets/js/traducciones'; 
import { ActionsService } from 'src/app/services/ActionService.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-publicaciones',
  templateUrl: './publicaciones.component.html',
  styleUrls: ['./publicaciones.component.scss'],
})
export class PublicacionesComponent implements OnInit {
  @Input() _id: number = null;
  @Input() type: number = null;
  @Input() title: string = '';
  @Input() archive: string = '';
  post :any;
  datos = {};
  defaultImage =
    'https://cdn.icon-icons.com/icons2/564/PNG/512/Add_Image_icon-icons.com_54218.png'; // Ruta de la imagen por defecto (ajusta la ruta según tu estructura de archivos)
  clickedImage: string | null = null;

  miFormularioCreate: FormGroup;
  miFormularioEdit: FormGroup;
  miFormulario: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    public postservice: PostsService,
    public presentToast: ActionsService,
    private sanitizer: DomSanitizer
  ) {}
  
  config = {
    placeholder: 'Ingrese una descipción',
    tabsize: 2,
    height: '170px',

  }
  getDescriptionHTML(description: string): SafeHtml {
   
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }
  takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Prompt,
      promptLabelHeader: TRANSLATIONS_ES.camera.label,
  promptLabelPhoto: TRANSLATIONS_ES.camera.gallery,
  promptLabelPicture: TRANSLATIONS_ES.camera.takePhoto,
      
       
    });
    // Para los textos del plugin, usa las traducciones
const translatedTakePhotoText = TRANSLATIONS_ES.camera.takePhoto;
const translatedGalleryText = TRANSLATIONS_ES.camera.gallery;
    var imageUrl = image.dataUrl;
    // Can be set to the src of an image now
    this.clickedImage = imageUrl;
  };

  ngOnInit() {
    this.miFormularioCreate = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
    });

    this.miFormularioEdit = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
    });
    this.type_modal();
  }
  async type_modal() {

    if (this.type == 1) {
      this.detailSuggestion();
      // console.log(this.post);

      this.datos = { titulo: 'Detalle Publicación', menu: false, back: true };
    } else {
      this.miFormulario = this.formBuilder.group({
        title: ['', Validators.required],
        description: ['', [Validators.required]],
      });
      this.datos = { titulo: 'Crear Publicación', menu: false, back: true };
    }
  }
  guardarFormularioCreate() {
    const title = this.title;
    const message = this.title;

    this.presentToast.presentAlert(title, message, () => {
    
      // Agrega aquí la lógica que deseas ejecutar al confirmar la alerta
      this.storePosts();
    });
  }
  storePosts() {
    let titulo = this.miFormularioCreate.get('title').value;
    let description = this.miFormularioCreate.get('description').value;
    this.postservice.PostsStore(titulo, description, this.clickedImage).then(
      (res: any) => {
        this.presentToast.toast_succes('Dato guardado con exito');

        
        this.modalController.dismiss(res);
        // this.navCtrl.navigateForward('/menu/sugerencias', { replaceUrl: true });
      },
      (err) => {
        this.presentToast.toast_error(err.message);
      }
    );
  }
  async detailSuggestion() {

    this.postservice.detailPost(this._id).then(
      (res: any) => {

        /*
        this.miFormularioEdit.patchValue({
          // Rellena el formulario con los datos consultados en detailSuggestion()
          title: res.name,
          description: res.description,
        });
        */
       //console.log(res);
       
       this.post=res;
  
       
      
      },
      (err) => {
        console.log(err);
      }
    );
  }

  reservaForm: FormGroup;
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('es', options);
  }
  createForm() {
    this.reservaForm = this.formBuilder.group({
      telefono: new FormControl('', Validators.maxLength(10)),
      direccion: new FormControl(''),
      tipo_persona: new FormControl(1),
    });
  }
  customerForm(data) {
    //console.log(data);
    this.modalController.dismiss();
  }
}
