// @ts-nocheck
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActionsService } from 'src/app/services/ActionService.service';
import { PollsService } from 'src/app/services/polls.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss'],
})
export class PollComponent implements OnInit {
  datos = {};
  @Input() _id: number = null;
  @Input() title: string = null;
  @Input() type: number = null;
  @Output() onFinish = new EventEmitter();
  @Output() onNext = new EventEmitter();
  @Output() onPrevious = new EventEmitter();
  @ViewChild("wizardSlider", { static: false }) slider;
  selectedOption: number | null = null; // Inicializar con null
  selectedOptions: number[]   = [];
  constructor(
    private modalController: ModalController,
    public presentToast: ActionsService,
    public pollservice: PollsService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe
  
  ) {}
  survey: any = {

  };
  details: any = {

  };
  currentQuestionIndex = 0;
  fechaFormateada: string;
  titulo: string;
  currentQuestion ;
  inputAnswer :number;
  responses = [];
  answerIsValid = false;
    tamao(obj: any): string {
      const lengthOfObject = Object.keys(obj.options).length;
      //console.log(lengthOfObject);
    
      return lengthOfObject === 0 ? obj.answers[0]: obj.answers.join(', ');
    }
  
  handleAnswerChange() {
    if (this.currentQuestion.question_type_id === 1) {
      this.answerIsValid = this.selectedOption !== null;
    } else if (this.currentQuestion.question_type_id === 2) {
      this.answerIsValid = this.selectedOptions.length > 0;

      
    } else if (this.currentQuestion.question_type_id === 3) {

  
      // Verifica si el input no está vacío y es un número válido
      this.answerIsValid = this.inputAnswer != null && !isNaN(this.inputAnswer);
    
    }
  }
  fecha(date){
  return  this.fechaFormateada = this.datePipe.transform(date, 'dd/MM/yyyy HH:mm');

    
  }
  nextQuestion() {
    this.saveAnswer();
    this.answerIsValid=false
    this.currentQuestionIndex++;
    this.currentQuestion = this.survey.questions[this.currentQuestionIndex];
  }

  previousQuestion() {
    this.currentQuestionIndex--;
    this.currentQuestion = this.survey.questions  [this.currentQuestionIndex];
  }

  checkboxChanged(event: any, optionId: number) {
    const isChecked = event.detail.checked;
 
    if (isChecked) {
    
      // Agregar el optionId al arreglo de opciones seleccionadas
      if (!this.selectedOptions.includes(optionId)) {
        this.selectedOptions.push(optionId);
      }
    } else {
      // Quitar el optionId del arreglo de opciones seleccionadas
      const index = this.selectedOptions.indexOf(optionId);
      if (index !== -1) {
        this.selectedOptions.splice(index, 1);
      }
    }
    this.handleAnswerChange()

  }
  async detailPoll() {

    this.pollservice.detailPoll(this._id).then(
      (res: any) => {

        this.survey = res; // Llenar la variable survey con los datos obtenidos
  
        // Una vez que tienes la encuesta, puedes inicializar la primera pregunta
        this.currentQuestionIndex = 0;
        this.loadCurrentQuestion(); // Llamar a loadCurrentQuestion después de llenar survey
      },
      (err) => {
        console.log(err);
      }
    );
  }
  loadCurrentQuestion() {
    if (this.survey && this.survey.questions.length > this.currentQuestionIndex) {
      this.currentQuestion = this.survey.questions[this.currentQuestionIndex];
    } else {
      console.error('Invalid currentQuestionIndex or survey data.');
    }

    
  }
    
  async AnswerPoll() {

    this.pollservice.RellenoPoll(this._id).then(
      (res: any) => {
       this.details=res;
       this.titulo=this.title

       
      
      },
      (err) => {
        console.log(err);
      }
    );
  }
  saveAnswer() {
    if (this.currentQuestion.question_type_id === 1) {
      this.responses[this.currentQuestionIndex] = {
        question_id: this.currentQuestion.id,
        number:this.currentQuestion.number,
        name:this.currentQuestion.name,
        answers: {
          options: {},
          },
        },
      
    
      this.currentQuestion.options.forEach((option, index) => {
        if (this.selectedOption === option.id) {
          this.responses[this.currentQuestionIndex].answers.options[index] = option.id;
          this.responses[this.currentQuestionIndex].answers.answers = [option.name];
        } else {
          this.responses[this.currentQuestionIndex].answers.options[index] = option.id;
        }
      });
    }
    
    else if (this.currentQuestion.question_type_id === 2) {
      this.responses[this.currentQuestionIndex] = {
        question_id: this.currentQuestion.id,
        name:this.currentQuestion.name,
        number:this.currentQuestion.number,
        answers: {
          options: {},
          },
        },
    
      
      this.currentQuestion.options.forEach((option, index) => {
        if (this.selectedOptions.includes(option.id)) {
          this.responses[this.currentQuestionIndex].answers.options[index] = option.id;
        } else {
          this.responses[this.currentQuestionIndex].answers.options[index] =  option.id;
        }
      });
    
      this.responses[this.currentQuestionIndex].answers.answers = this.selectedOptions.map(optionId => {
        const option = this.currentQuestion.options.find(option => option.id === optionId);
        return option ? option.name : '';
      });
    }
       else if (this.currentQuestion.question_type_id === 3) {
      const newResponse = {
        question_id: this.currentQuestion.id,
        name:this.currentQuestion.name,
        number:this.currentQuestion.number,
        answers: {
          options: {}, // No está claro qué se debe hacer aquí
          answers: {
            0: this.inputAnswer,
          }, // No está claro qué se debe hacer aquí
        },
      };
    
      this.responses[this.currentQuestionIndex] = newResponse;
    } 
   // console.log(this.responses);
    
    
  }
  
  canProceed(): boolean {
    return this.answerIsValid;
  }
  getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  postPoll(){
    let data = {
			poll_id:this._id,
			answers: this.responses,
		};
    this.pollservice.PollStore(data).then(
      (res: any) => {
        this.presentToast.toast_succes('Respuesta guardada con exito');

        if (this.type == 1) {


         this.modalController.dismiss(this.type);
        }
      },
      (err) => {
        console.log(err);
      }
    );

  
  }
  submitSurvey() {  

   
    this.presentToast.presentAlert('Encuesta', 'enviar encuesta', () => {
     
      // Agrega aquí la lógica que deseas ejecutar al confirmar la alerta
      this.postPoll();
    });
  
  
  }
  ngOnInit() {
 

    
    this.type_modal()

  }
 async type_modal() {

    if (this.type == 0) {

      // console.log(this.post);

      this.datos = { titulo: 'Ver Encuesta', menu: false, back: true };
      this.AnswerPoll()
    } else {
   
      this.datos = { titulo: 'Rellenar Encuesta', menu: false, back: false };
      this.detailPoll()
    }
  }

}
