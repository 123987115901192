import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

import { Router } from '@angular/router';

@Component({ selector: 'app-header', templateUrl: './header.component.html', styleUrls: ['./header.component.scss'], })

export class HeaderComponent implements OnInit {
  @Input() datos: any = '';
  logo = 'assets/logo/3.png';

  constructor(
    private navCtrl: NavController,
    private location: Location,
    public modalController: ModalController,
    private router: Router
  ) { }

  home() {
    this.datos.menu ? this.location.back() : this.modalController.dismiss();
    this.navCtrl.navigateForward('/menu/home')
    // Borra el historial actual
  this.location.replaceState('/menu/home');
   
  }

  goBack() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
   // console.log("llego");
    
    this.datos.menu ? this.location.back() : this.modalController.dismiss();
  }

 


  ngOnInit() { }

}
