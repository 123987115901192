import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ModalController,
  NavController,
  ToastController,
} from '@ionic/angular';
import { es } from 'date-fns/locale';
import { format, parseISO } from 'date-fns';

import { BitacoraService } from 'src/app/services/bitacora.service';
import { ActionsService } from 'src/app/services/ActionService.service';
import { guest_type } from 'src/assets/js/guest_type';
import { type } from 'src/assets/js/type_visit';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IonDatetime } from '@ionic/angular';
import { ModalQRComponent } from '../../modal-qr/modal-qr.component';
import { GenerateQRComponent } from '../../generate-qr/generate-qr.component';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { TRANSLATIONS_ES } from '../../../../assets/js/traducciones'; 

interface Type {
	id: number;
	titulo: string;
	identification: string;

  }
  interface guest_type {
    id: number;
    titulo: string;
    identification: string;
  
    }
@Component({
  selector: 'app-bitacoras',
  templateUrl: './bitacora.component.html',
  styleUrls: ['./bitacora.component.scss'],
})
export class BitacoraComponent implements OnInit {
  @ViewChild('datetime') datetime: IonDatetime;
  @Input() _id: number = null;
  @Input() type: number = null;
  @Input() title: string = '';
	types: Type[] = type;
	guest_types: guest_type[] = guest_type;
  formattedSString = '';
  formattedStart = '';
  miFormulario: FormGroup;
  miFormularioCreate: FormGroup;
  miFormularioEdit: FormGroup;
  focused: boolean = false;
    showPicker = false;
  bitacoras :any;
  logo = 'assets/logo/3.png';
  datos = { titulo: 'hola', menu: false, back: true };
  location: any;
  clickedImage;
  selectedValue: any;
  minDate: string;
  filteredResults = []; 
  residencia=''
  residencia_name=''
  residence = JSON.parse(localStorage.getItem('profile')).residences;
 

  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    public bitacora: BitacoraService,
    public navCtrl: NavController,
    public presentToast: ActionsService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer
  ) {}
  config = {
    placeholder: 'Ingrese una observación (opcional)',
    tabsize: 2,
    height: '170px',

  }
  takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Prompt,
      promptLabelHeader: TRANSLATIONS_ES.camera.label,
  promptLabelPhoto: TRANSLATIONS_ES.camera.gallery,
  promptLabelPicture: TRANSLATIONS_ES.camera.takePhoto,
      
       
    });
    // Para los textos del plugin, usa las traducciones
const translatedTakePhotoText = TRANSLATIONS_ES.camera.takePhoto;
const translatedGalleryText = TRANSLATIONS_ES.camera.gallery;
    var imageUrl = image.dataUrl;
    // Can be set to the src of an image now
    this.clickedImage = imageUrl;
  };

  getDescriptionHTML(description: string): SafeHtml {
   
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }
  searchByName(event: any) {
    const query = event.target.value.toLowerCase();
    this.filteredResults = this.searchSocialAreas(query);
  }
  resetResidence(){
  
    this.reset()
    
  }
  async reset(){
    this.bitacora.resetResidences()
      .then(
        (res: any) => {
          let profileData = JSON.parse(localStorage.getItem('profile'));
          profileData.residences = res; // Reemplaza "nuevoValor" con el valor que deseas asignar
          localStorage.setItem('profile', JSON.stringify(profileData));

          this.presentToast.toast_warning('Residencia actualizada con exito con exito');
        //  this.modalController.dismiss(res);
  
        },
        (err) => {
          this.presentToast.toast_error(err.message);
        }
      );
  }
  searchSocialAreas(query: string) {
    return this.residence.filter(area => area.number.toLowerCase().includes(query));
  }
  initial(){

    
    this.filteredResults=this.residence
  }
  onBlur(event: any) {
    this.focused = false;

    this.onTouched();
  }
  async Result(data) {
    const modal = await this.modalController.getTop();
    if (modal) {
      modal.dismiss();
    }
  
    // Agrega un pequeño retraso antes de cambiar el valor del formulario
    setTimeout(() => {
      this.miFormularioCreate.get('residencia').setValue(data.number);
      this.residencia_name = data.id;
    }, 200); // 200 milisegundos (ajusta esto según tus necesidades)
  }
  
  private onTouched: () => void = () => {};
  dateChanged(value) {
    

    const parsedDate = parseISO(value.detail.value);
    const formattedDate = format(parsedDate, 'MMM d, yyyy', { locale: es });

    this.formattedSString = formattedDate;

  }
  dateInicio(value) {


    const parsedDate = parseISO(value.detail.value);
    const formattedDate = format(parsedDate, 'MMM d, yyyy', { locale: es });

    this.formattedStart = formattedDate;
    this.showPicker=false

      // Luego, cierra el ion-datetime

  }
  ngOnInit() {
    const currentDate = new Date();
    this.filteredResults=this.residence
    const ecuadorTimezoneOffset = -5; // Ecuador's timezone offset in                                    hours
    currentDate.setHours(currentDate.getHours() + ecuadorTimezoneOffset); // Adjust for Ecuador's timezone
    this.minDate = currentDate.toISOString();

    this.miFormularioCreate = this.formBuilder.group({

      category: [{ value: '', disabled: false },Validators.required], // Bloquear el campo 'fullname'
      residencia: [{ value: '', disabled: false },Validators.required], // Bloquear el campo 'fullname'
     
      observation: [{ value: '', disabled: false },Validators.required], // Bloquear el campo 'observation'
     // Bloquear el campo 'start'
      typo: [{ value: 'BITACORA', disabled: false },Validators.required], // Bloquear el campo 'typo'

    });
    
    this.miFormularioEdit = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      residencia: [{ value: '', disabled: false },Validators.required], 
    });
    this.type_modal();
  }

  async type_modal() {
   

    if (this.type == 1) {
      this.detailSuggestion();

   
      
      this.datos={titulo: this.title, menu: false, back: true }
    } else {
   
      this.datos={titulo: "Ingresar Bitacora", menu: false, back: true }
    }
  }
  async detailSuggestion() {

    
    this.bitacora.detailBitacora(this._id).then(
      (res: any) => {
   
       this.bitacoras=res
      },
      (err) => {
        console.log(err);
      }
    );
  }
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('es', options);
  }
    guardarFormularioCreate() {
    const title = this.title;
    const message = this.title;

    this.presentToast.presentAlert(title, message, () => {
      const formData = {
        category: this.miFormularioCreate.get('category').value,
        residence_id:this.residencia_name,
        observation: this.miFormularioCreate.get('observation').value,
        date: this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss'),
        archive:this.clickedImage
      };
      
    

      
    this.storeBitacora(formData)
      
    });
  }
  async storeBitacora(formData){
    this.bitacora
      .createBitacora(formData)
      .then(
        (res: any) => {
          this.presentToast.toast_succes('Dato guardado con exito');
       
          
          this.modalController.dismiss(res);
       
        },
        (err) => {
          console.log(err.message);
          
          this.presentToast.toast_error(err.message);
        }
      );
  }
  reservaForm: FormGroup;

  lookForClient(reserva_id) {

  }

  customerForm(data) {

    this.modalController.dismiss();
  }

 async startScan(){
    const modal = await this.modalController.create({
			component: ModalQRComponent,
			cssClass: 'my-custom-class',
			componentProps: {  }
		});
		modal.onWillDismiss().then((res) => { });
		return await modal.present();
  }
  async generateqr(){
    const modal = await this.modalController.create({
			component: GenerateQRComponent,
			cssClass: 'my-custom-class',
			componentProps: {  }
		});
		modal.onWillDismiss().then((res) => { });
		return await modal.present();
  }

}
