import { Injectable } from '@angular/core';
import { urls } from '../../environments/environment';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';
import { ActionsService } from './ActionService.service';

@Injectable({ providedIn: 'root' })
export class PostsService {


	constructor(private http: HttpService, public global: ActionsService) { }

	

    viewPosts(data, load) {
		
		
		return new Promise((resolve, reject) => {
			this.http.get(urls.posts + '?token='+this.global.token()+'& start='+data.start +'& limit='+data.limit+'& search='+data.search, load)
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
    detailPost(id) {
		
		return new Promise((resolve, reject) => {
			this.http.get(urls.posts +'/'+id+ '?token='+this.global.token(), "Cargando...")
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
    PostsStore(title, description, archive) {
		let data = {
			name:title,
			description: description,
            archive:archive
		};
		return new Promise((resolve, reject) => {
			this.http.post(urls.posts+'/store'+ '?token='+this.global.token(), true, data, "Guardando..")
				.then(async (res: any) => {
					resolve(res);
				
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
	


	

}
