import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators,FormBuilder } from '@angular/forms';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { ActionsService } from 'src/app/services/ActionService.service';
import { VisitComponent } from '../../form/visit/visit.component';
import { ReservaService } from 'src/app/services/reserva.service';
import { BitacoraService } from 'src/app/services/bitacora.service';
import { VisitService } from 'src/app/services/visit.service';
import { ViewBusinessComponent } from '../view-business/view-business.component';
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
  selector: 'app-view-visit-data',
  templateUrl: './view-visit-data.component.html',
  styleUrls: ['./view-visit-data.component.scss'],


})
export class ViewVisitDataComponent implements OnInit {
  @Input() servicios: any;
  @Input() type: number = null;
  residencia_name=''
  filteredResults = []; 
  @Input() users: { id: number, full_name:string, idn:string  }[] = [];
  @Input() establishments: { id: number, extras_data: { numero: number } }[] = [];
  myForm: FormGroup;
  constructor( public reserva: ReservaService,   private alertController: AlertController,    public bitacora: BitacoraService,
    public presentToast: ActionsService,  public visit: VisitService,	public modalController: ModalController,private formBuilder: FormBuilder,private service:ActionsService,private popoverController: PopoverController) {
    this.myForm = this.formBuilder.group({
      id: ['', Validators.required],
      name:[''],
    });
   }

  ngOnInit() {
    this.establishments = this.reserva.residence();
    this.users = this.reserva.users();
  }
  call(){

    
  }
  resetResidence(){
if (this.type==1) {
  this.reset()
}
if (this.type==2) {
  this.resetUser()
}
  
    
  }
  initial(){
   // console.log("si llego ");
    this.filteredResults=this.establishments
  }
  portChange(event: {
    component: IonicSelectableComponent,
    
    
    value: any
  }) {
  //  console.log('port:', event.value.id);
    const controlId = this.myForm.get('id') as FormGroup;
  controlId.setValue(event.value.id);
  }
  async resetUser(){
    this.bitacora.resetUsers()
      .then(
        (res: any) => {
          let profileData = JSON.parse(localStorage.getItem('profile'));
          profileData.users = res; // Reemplaza "nuevoValor" con el valor que deseas asignar
          localStorage.setItem('profile', JSON.stringify(profileData));

          this.presentToast.toast_warning('Usuarios actualizado con exito. ');
        //  this.modalController.dismiss(res);
  
        },
        (err) => {
          this.presentToast.toast_error(err.message);
        }
      );
  }
  async reset(){
    this.bitacora.resetResidences()
      .then(
        (res: any) => {
          let profileData = JSON.parse(localStorage.getItem('profile'));
          profileData.residences = res; // Reemplaza "nuevoValor" con el valor que deseas asignar
          localStorage.setItem('profile', JSON.stringify(profileData));

          this.presentToast.toast_warning('Residencia actualizada con exito.');
        //  this.modalController.dismiss(res);
  
        },
        (err) => {
          this.presentToast.toast_error(err.message);
        }
      );
  }
  Result(data){

 
    this.residencia_name=data.number
        this.modalController.dismiss(data);
      }
  async detailvisit (visit_id) {
   // this.closePopover();
 if (this.type==0) {
   this.detail_Response(visit_id);
 }else{
  this.visit.detailVisit(visit_id,'').then(
    (res: any) => {
    // console.log(res.visit.length);
      if (res.visit.length==0) {
       // this.modalController.dismiss();
        this.closePopover(res);
        this.presentAlert()
      }
     if (res.visit.length == 1) return this.detail_Response(res.visit[0].id);
     if (res.visit.length>1) {
       this.componentBusiness(res.visit);
     }
    
    },
    (err) => {
      //this.presentAlert()
      this.modalController.dismiss();
      console.log(err);
    

    }
  );
 }

	
   
	  }
    async presentAlert() {
      const alert = await this.alertController.create({
        header: 'Alerta',
        subHeader: 'Mensaje',
        message: 'Visita no encontrada',
        buttons: ['OK'],
      });
  
      await alert.present();
    }
    async componentBusiness(bussiness) {
      const modal = await this.popoverController.create({
        component: ViewBusinessComponent,
        componentProps: {
          bussiness,
          type: this.type,
        }
      });
      modal.onWillDismiss().then((res) => {
      //console.log(res.data.id);
      
        if (res?.data) this.detail_Response(res.data.id)
      });
      return await modal.present();
    }
  
    async detail_Response(visit_id){
      const modal = await this.modalController.create({
        component: VisitComponent,
        componentProps: {
        _id: visit_id,
        type: 1,
        title: 'Detalle Visitas',
        },
      });
      modal.onWillDismiss().then((res) => {
      //  console.log(res);
        
       this.closePopover(res);
      });
       modal.dismiss()
      return await modal.present();
    }
    closePopover(res) {
      this.popoverController.dismiss(res);
    }
    
  onSubmit() {
    if (this.myForm.valid) {
      // Aquí puedes realizar la acción de enviar el formulario.
    // console.log(this.myForm.get('id').value);
     
      this.detailvisit(this.myForm.get('id').value)
    }
  }
}
