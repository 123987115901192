import { Injectable } from '@angular/core';
import { urls } from '../../environments/environment';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';
import { ActionsService } from './ActionService.service';

@Injectable({ providedIn: 'root' })
export class BitacoraService {
	business() { return JSON.parse(localStorage.getItem("business")).user_id };
	constructor(private http: HttpService, public global: ActionsService) { }

	

    viewBitacora(data, load) {

		return new Promise((resolve, reject) => {
			this.http.get(urls.bitacora  + '?token='+this.global.token()+'& type='+data.type +'& start='+data.start +'& limit='+data.limit+'& search='+data.search+'& end_date='+data.end_date  +'& residence_id='+data.residence_id  ,  load)
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
	
	detailBitacora(id) {
		return new Promise((resolve, reject) => {
			this.http.get(urls.bitacora +'/'+id+ '?token='+this.global.token(), "Cargando...")
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
	resetResidences() {
		
		
		return new Promise((resolve, reject) => {
			this.http.get(urls.residence + '?token='+this.global.token(), "Cargando...")
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
	resetUsers() {
		
		
		return new Promise((resolve, reject) => {
			this.http.get(urls.users + '?token='+this.global.token(), "Cargando...")
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
	
	createBitacora(data) {
	
		return new Promise((resolve, reject) => {
			this.http.post(urls.bitacora+'/store'+ '?token='+this.global.token(), true, data, "Guardando..")
				.then(async (res: any) => {
					resolve(res);
					//this.sugerenciaCreadaSource.next();
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}

	

}
