
import { Component, Input, OnInit,Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, PopoverController, ToastController } from '@ionic/angular';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { ActionsService } from 'src/app/services/ActionService.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { VisitService } from 'src/app/services/visit.service';
@Component({
  selector: 'app-movement',
  templateUrl: './movement.component.html',
  styleUrls: ['./movement.component.scss'],
})


export class MovementComponent  implements OnInit {
	@Input() _id: number = null;
	@Input() servicios: any;
	datos = { titulo: "Ver Movimientos", menu: false, back: true }
	reservaForm: FormGroup;
	allmovement: any[] = [];
	allmovement_aux: any[] = [];
	cont = 0;
	palabra_buscar = '';
	start = 0;
	limit = 10;
	campanero;
	load = 'Cargando...';

	currentIndex: number = 10;
  	constructor(
		public visit: VisitService
  ) {
 
   }
   ionViewDidEnter() {
    this.start = 0;
    this.allmovement = [];
    this.campanero = true;
    this.palabra_buscar = '';
    this.cont = 0;


    this.allmovement_aux = [];
    this.loadUserData(this.start, this.limit, '', this.load);
  }



  loadUserData(start, limit, user_search, loading) {

	
    let data = {
		visit_id:this._id,
      start: start,
      limit: limit,
      search: user_search,
    };

    this.visit.viewMovement(data, loading).then(
      (data: any) => {
        data = data;
        this.currentIndex = data.length;

        if (data[0]) {
          if (this.cont == 0) {
            this.allmovement_aux = data;
            this.cont = 1;
          }
          this.insertData(data, 0);
        } else {
          this.campanero = false;
        }
      },
      (error) => {
        if (error.error.error.status == 405) {
          this.allmovement = [];
          this.campanero = true;
          this.start = 0;
          this.loadUserData(start, limit, user_search, this.load);
        } else if (error.error.error.status == 401) {
          localStorage.clear();

          // this.router.navigate([""]);
          //	this.navCtrl.navigateForward('');
        } else {
          //this.presentToast(error.message)
        }
      }
    );
  }
  insertData(data, cont) {
    data.forEach((valor, indice, array) => {
      data[indice].load = false;
      cont == 1
        ? this.allmovement.unshift(data[indice])
        : this.allmovement.push(data[indice]);
    });
    if (this.cont == 0 || cont == 1) {
      this.allmovement_aux = [];
      this.allmovement_aux = [...this.allmovement];
      this.cont = 1;
    }

    this.allmovement = data;
  }
  ngOnInit() {}

 






}
