import { Component, Input, OnInit,ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormArray ,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl
} from '@angular/forms';
import {
  AlertController,
  ModalController,
  NavController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { es } from 'date-fns/locale';
import { format, parseISO } from 'date-fns';

import { VisitService } from 'src/app/services/visit.service';
import { ActionsService } from 'src/app/services/ActionService.service';
import { guest_type } from 'src/assets/js/guest_type';
import { type } from 'src/assets/js/type_visit';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IonAccordionGroup } from '@ionic/angular';
import { ModalQRComponent } from '../../modal-qr/modal-qr.component';
import { GenerateQRComponent } from '../../generate-qr/generate-qr.component';
import { MovementComponent } from '../movement/movement.component';
import { ViewObsercationDataComponent } from '../../popover/view-observation-data/view-observation-data.component';
interface Type {
	id: number;
	titulo: string;
	identification: string;

  }
  interface guest_type {
    id: number;
    titulo: string;
    identification: string;
  
    }
@Component({
  selector: 'app-visits',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.scss'],
})
export class VisitComponent implements OnInit {
  @ViewChild('accordionGroup', { static: true }) accordionGroup: IonAccordionGroup;

  @Input() _id: number = null;
  @Input() type: number = null;
  @Input() title: string = '';
	types: Type[] = type;
	guest_types: guest_type[] = guest_type;
  formattedSString = '';
  formattedStart = '';
  miFormulario: FormGroup;
  miFormularioCreate: FormGroup;
  miFormularioEdit: FormGroup;
  focused: boolean = false;
  minDate: string;
    showPicker = false;
    showPicker2 = false;
    selectedType: string;
  visits :any
  observation = '';
  datos = { titulo: 'hola', menu: false, back: true };
  location: any;
  clickedImage;
  selectedValue: any;
  elementosVisibles: boolean[] = [];
  invitados: any[] = [];
  rol = JSON.parse(localStorage.getItem('profile')).rol_name;

  agregarInvitado() {
    this.invitados.push({});
  }
  recibirDatos(invitadoIndex: number, datos: any) {
    // Actualizar el objeto invitado en el arreglo con los nuevos datos
    this.invitados[invitadoIndex] = datos;
  }
  enviarDatos() {
    // Aquí puedes enviar this.invitados a través de una solicitud HTTP o realizar otras acciones

  }

  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    public visit: VisitService,
    public navCtrl: NavController,
    public presentToast: ActionsService,
    private datePipe: DatePipe,    
    public popoverController: PopoverController,
    private alertController: AlertController,
    private sanitizer: DomSanitizer
  ) {

}
  config = {
    placeholder: 'Ingrese una observación (opcional)',
    tabsize: 2,
    height: '170px',

  }
 // Función para mostrar u ocultar un elemento según su índice
 tipoSeleccionado(event) {
  this.selectedType = event.detail.value;
// Obtén la fecha y hora actual en UTC
const currentDate = new Date();

// Ajusta la fecha y hora al huso horario de Ecuador (UTC-5)
currentDate.setUTCHours(currentDate.getUTCHours() - 5);

// Formatea la fecha y hora actual en el formato ISO 8601 ('yyyy-MM-ddTHH:mm:ss.sssZ')
const formattedDate = currentDate.toISOString();

// Establece la fecha y hora en los campos 'start' y 'end' de tu formulario
this.miFormularioCreate.get('start').setValue(formattedDate);
this.miFormularioCreate.get('end').setValue(formattedDate);

}
 toggleElement(index: number) {
  this.elementosVisibles[index] = !this.elementosVisibles[index];

  
// Actualizar el array de invitados cuando se cambie la visibilidad
}

  getDescriptionHTML(description: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }
  onBlur(event: any) {
    this.focused = false;

    this.onTouched();
  }
  private onTouched: () => void = () => {};
  dateChanged(value) {


    const parsedDate = parseISO(value.detail.value);
    const formattedDate = format(parsedDate, 'MMM d, yyyy', { locale: es });

    this.formattedSString = formattedDate;

    this.showPicker=false

  
    
  }
  toggleAccordion = () => {
    const nativeEl = this.accordionGroup;
    if (nativeEl.value === 'second') {
      nativeEl.value = undefined;
    } else {
      nativeEl.value = 'second';
    }
  };
  dateInicio(value) {


    const parsedDate = parseISO(value.detail.value);
    const formattedDate = format(parsedDate, 'MMM d, yyyy', { locale: es });

    this.formattedStart = formattedDate;

    this.showPicker=true

  
  }
  annadirDireccion() {
    (<FormArray>this.miFormularioCreate.controls['guest']).push(new FormGroup({
      'fullname': new FormControl('', Validators.required),
      'idn': new FormControl('', Validators.required),
       'email': new FormControl('')
    }));
  }
  eliminarDireccion(index: number) {
    (<FormArray>this.miFormularioCreate.controls['guest']).removeAt(index);
  }
  // Función para determinar si habilitar el botón "In" para una visita específica
  isInButtonEnabled(visit: any): boolean {
    if (visit.visit.extras_data.type === 'ADVANCE' && visit.last_movement?.type !== 'IN') {
      // Habilita el botón "In" si el tipo es ADVANCE y el último movimiento no fue IN
      return true;
    } else if (visit.visit.extras_data.type === 'SIMPLE' && !visit.last_movement) {
      // Habilita el botón "In" si el tipo es SIMPLE y no hay ningún movimiento registrado
      return true;
    }
    return false; // En otros casos, deshabilita el botón "In"
  }

  isOutButtonEnabled(visit: any): boolean {
    if (visit.visit.extras_data.type === 'ADVANCE' && visit.last_movement?.type == 'IN') {
      // Habilita el botón "Out" si el tipo es ADVANCE y el último movimiento no fue IN
      return true;
    } else if (
      visit.visit.extras_data.type === 'SIMPLE' &&
      visit.last_movement &&
      visit.last_movement.type === 'IN'
    ) {
      // Si es SIMPLE y el último movimiento es IN, habilita el botón "Out"
      return true;
    }
    // En otros casos, deshabilita el botón "Out"
    return false;
  }
  async presentEnviar(formData,status) {
    this.observation=""
    const alert = await this.alertController.create({
      header: 'ENVIAR',
      subHeader: 'Antes de Registrar Movimento!!',
      message: 'Desea enviar una observación',
      inputs:[
        {
          type: 'textarea',
          placeholder: 'Ingrese una observación',
          cssClass: 'alert-button-cancel',
          name: 'observacionInput', // Nombre del input para referencia
        },
      ],
      buttons: [
        { text: 'Cancelar', role: 'cancel', cssClass: 'secondary', handler: () => {
         // this.guardarMovement(formData,status);
        } },
        {
          text: 'Aceptar',
          handler: (data) => {
            // Acceder directamente al valor del textarea usando el nombre del input
          this.observation = data.observacionInput;
            //console.log('Observación ingresada:', this.observation);
            this.guardarMovement(formData,status);
          }
        },
        ],
    });

    await alert.present();
  }

  // Función para manejar los clics en los botones "In" y "Out" para una visita específica
  handleInOutClick(status: string, visit: any): void {
    // Implementa tus acciones aquí para la visita específica (visit) y el estado (status)
   
    let formData={
      visit_id:visit.visit.id,
      date: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      type:status,
      residence_id:visit.visit.residence_id,
      observation:this.observation
    }
    const title = 'Guardar Movimiento';
    const message = 'Guardar Movimiento';
this.presentEnviar(visit,status)
 
 


  }
  async viewServicioData(formData,status) {
		const popover = await this.popoverController.create({
			component: ViewObsercationDataComponent,
			mode: "ios",
			cssClass: "custom-popover",
			componentProps: {  }
		});

    popover.onWillDismiss().then((res) => {
    //  console.log("llego al popover");
     
      this.observation=res.data.description

      this.guardarMovement(formData,status);
    });
    return await popover.present();
   
	}
 async viewMovement(id){

  
    const modal = await this.modalController.create({
      component: MovementComponent,
      componentProps: {
        _id: id.visit.id,
        type: 1,
     
        title: 'Movimiento Visita',
      },
    });
    modal.onWillDismiss().then((res) => {});
    return await modal.present();
  }
  guardarMovement(visit,status){
   // console.log(this.observation);
    
    let formData={
      visit_id:visit.visit.id,
      date: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      type:status,
      residence_id:visit.visit.residence_id,
      observation:this.observation
    }

    
    this.visit.createMovementVisit(formData)
    .then(
      (res: any) => {
        this.presentToast.toast_succes('Dato guardado con exito');
        this.modalController.dismiss(res);
     // this.modalQRs(res,0)
      },
      (err) => {
        console.log(err);
        
        this.presentToast.toast_error(err.message);
      }
    );
  }
  ngOnInit() {
    const currentDate = new Date();
    const ecuadorTimezoneOffset = -5; // Ecuador's timezone offset in                                    hours
    currentDate.setHours(currentDate.getHours() + ecuadorTimezoneOffset); // Adjust for Ecuador's timezone
    this.minDate = currentDate.toISOString();

    this.miFormularioCreate = this.formBuilder.group({

      fullname: [{ value: '', disabled: false },Validators.required], // Bloquear el campo 'fullname'
      id: [{ value: '', disabled: false },Validators.required], // Bloquear el campo 'id'
      email: [{ value: '', disabled: false }], // Bloquear el campo 'email'
      phone: [{ value: '', disabled: false }], // Bloquear el campo 'phone'
      car_plate: [{ value: '', disabled: false },Validators.required], // Bloquear el campo 'phone'
      observation: [{ value: '', disabled: false },Validators.required], // Bloquear el campo 'observation'
      start: [{ value: new Date().toISOString(), disabled: false },Validators.required], // Bloquear el campo 'start'
      end: [{ value: new Date().toISOString(), disabled: false },Validators.required], // Bloquear el campo 'end'
      typo: [{ value: this.types[0].identification, disabled: false },Validators.required], // Bloquear el campo 'typo'
      guestType: [{value: this.guest_types[0].identification, disabled: false },Validators.required], // Bloquear el campo 'guestType'
      guest:new FormArray([])
    });
  
    this.miFormularioEdit = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
    });
    this.type_modal();
  }

  async type_modal() {


    if (this.type == 1) {
      this.detailSuggestion();

   
      
      this.datos={titulo: "Ver Visita", menu: false, back: true }
    } else {
   
      this.datos={titulo: "Ingresar Visita", menu: false, back: true }
    }
  }
  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Alerta',
      subHeader: 'Mensaje',
      message: 'Visita no encontrada',
      buttons: ['OK'],
    });

    await alert.present();
  }
  async detailSuggestion() {
    this.visit.detailVisit(this._id,'Cargando...').then(
      (res: any) => {
       // console.log(res);
        if (res.visit.length==0) {
          // this.modalController.dismiss();
          
           this.presentAlert()
           this.modalController.dismiss();
         }else{
       this.visits=res    
         }
       
      },
      (err) => {
        this.presentAlert()
        this.modalController.dismiss();
        console.log(err);
      

      }
    );
  }
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('es', options);
  }
    guardarFormularioCreate() {
    const title = this.title;
    const message = this.title;

    this.presentToast.presentAlert(title, message, () => {
      if (this.miFormularioCreate.get('typo').value === 'SIMPLE') {
        const startDateValue = this.miFormularioCreate.get('start').value;
        const startDate = new Date(startDateValue); // Convierte el valor a un objeto Date
        startDate.setHours(0, 0, 0, 0); // Establece la hora en 00:00:00
        this.miFormularioCreate.get('start').setValue(startDate);
      
        const endDateValue = this.miFormularioCreate.get('end').value;
        const endDate = new Date(endDateValue); // Convierte el valor a un objeto Date
        endDate.setHours(23, 59, 59, 999); // Establece la hora en 23:59:59.999
        this.miFormularioCreate.get('end').setValue(endDate);
      
        // Formatea las fechas en el formato deseado 'yyyy-MM-dd HH:mm:ss'
        const formattedStartDate = this.datePipe.transform(startDate, 'yyyy-MM-dd HH:mm:ss');
        const formattedEndDate = this.datePipe.transform(endDate, 'yyyy-MM-dd HH:mm:ss');
      
    
        const formData = {
          full_name: this.miFormularioCreate.get('fullname').value,
          idn: this.miFormularioCreate.get('id').value,
          email: this.miFormularioCreate.get('email').value,
          phone: this.miFormularioCreate.get('phone').value,
          residence_id:this.presentToast.residence()[0].id,
          observation: this.miFormularioCreate.get('observation').value,
          start:formattedStartDate,
          end:formattedEndDate, 
          type:this.miFormularioCreate.get('typo').value,
          car_plate:this.miFormularioCreate.get('car_plate').value,
          extras_data: {
            type: this.miFormularioCreate.get('typo').value,
            guest_type: this.miFormularioCreate.get('guestType').value,
            guests: this.miFormularioCreate.get('guest').value,
          }
        };

        this.storevisit(formData)
      }
      else{

         const formData = {
        full_name: this.miFormularioCreate.get('fullname').value,
        idn: this.miFormularioCreate.get('id').value,
        email: this.miFormularioCreate.get('email').value,
        phone: this.miFormularioCreate.get('phone').value,
        residence_id:this.presentToast.residence()[0].id,
        observation: this.miFormularioCreate.get('observation').value,
        start: this.datePipe.transform(this.miFormularioCreate.get('start').value, 'yyyy-MM-dd HH:mm:ss'),
        end:this.datePipe.transform(this.miFormularioCreate.get('end').value, 'yyyy-MM-dd HH:mm:ss'), 
        type:this.miFormularioCreate.get('typo').value,
        car_plate:this.miFormularioCreate.get('car_plate').value,
        extras_data: {
          type: this.miFormularioCreate.get('typo').value,
          guest_type: this.miFormularioCreate.get('guestType').value,
          guests: this.miFormularioCreate.get('guest').value,
        }
      };
//console.log(formData);

      this.storevisit(formData)
      }
     
      const data = this.miFormularioCreate.value;
    

  
      

      
    });
  }
  async modalQRs(viasit, position) {

    
    const modal = await this.modalController.create({
      component: GenerateQRComponent,
      componentProps: {
       id: viasit.id,
        type: 0,
        title: 'Rellenar Encuesta',
      },
    });
    modal.onWillDismiss().then((res) => {
  
      if (res.data) {
        //this.ngOnInit();
    //    this.insertData([res.data], res.data.cont);
    this.modalController.dismiss(viasit
      );
      }
    });
    return await modal.present();
  }
  async storevisit(formData){
    this.visit
      .createVisit(formData)
      .then(
        (res: any) => {
          this.presentToast.toast_succes('Dato guardado con exito');
          this.modalController.dismiss(res);
       this.modalQRs(res,0)
        },
        (err) => {
          this.presentToast.toast_error(err.message);
          console.log(err);
          
        }
      );
  }
  reservaForm: FormGroup;

  lookForClient(reserva_id) {

  }

  customerForm(data) {

    this.modalController.dismiss();
  }



 
}
