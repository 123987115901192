import { FileOpener } from '@ionic-native/file-opener/ngx';
import { LocalNotifications, ScheduleOptions } from '@capacitor/local-notifications';
import { File } from '@ionic-native/file/ngx';
import { ModalController, AlertController, LoadingController, ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Capacitor, Plugins,  } from "@capacitor/core";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { HTTP } from "@ionic-native/http/ngx";
import { ActionsService } from './ActionService.service';
import { HttpService } from './http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { FileSaver }  from 'angular-file-saver';

const { FileSharer,Filesystem ,FilesystemDirectory} = Plugins;

@Injectable({ providedIn: 'root' })
export class PrintOptionsService {
 
	business() { return JSON.parse(localStorage.getItem("business")).user_id };

	constructor(
		private alertController: AlertController, 
		public global: ActionsService, 
		private modalController: ModalController, 
		private androidPermissions: AndroidPermissions,
		 public loadingController: LoadingController, 
		 public http: HTTP, 
		 public actionsService: ActionsService, 
		 private file: File, 
		 private fileOpener: FileOpener,
		 private https: HttpService
		 ) {
		LocalNotifications.addListener('localNotificationActionPerformed', (payload) => {
			const route = payload.notification.extra.route;
			// use route to redirect
			//this.presentAlert(route);
			 this.fileOpener.open(route, "application/pdf");
	  });
	 }

	async presentModalDocument(url,id, component,type) {
		let urls=''
		if (type=='debts') {
			 urls= `${url}${id}/1`;
		}else{
			 urls= `${url}`;
		}
		const modal = await this.modalController.create({
			component: component,
			cssClass: 'my-custom-class',
			componentProps: { id: id,url:urls }
		});
		modal.onWillDismiss().then((res) => { });
		return await modal.present();
	}

	async descargar(url,id, datos, transaccion_id,type) {
	
		let mensaje = id == 1 ? 'Descargar' : 'Compartir';
	
		if (id==1) {
				if (Capacitor.platform == "android") {
			const alert = await this.alertController.create({
				cssClass: mensaje,
				mode: "ios",
				header: mensaje,
				subHeader: '¿Está seguro de ' + mensaje + ' archivo?',
				buttons: [
					{ text: "Cancelar", role: "cancel", cssClass: "secondary", handler: (blah) => { }, },
					{ text: "Aceptar", handler: () => { this.download_http(url,datos, transaccion_id,type); }, },
				],
			});
			await alert.present();
		} else { this.descargarArchivo(url,id, datos, transaccion_id,type); }
		}else{
			if (Capacitor.platform == "android") {
				const alert = await this.alertController.create({
					cssClass: mensaje,
					mode: "ios",
					header: mensaje,
					subHeader: '¿Está seguro de ' + mensaje + ' archivo?',
					buttons: [
						{ text: "Cancelar", role: "cancel", cssClass: "secondary", handler: (blah) => { }, },
						{ text: "Aceptar", handler: () => { this.compartir(url,id, datos, transaccion_id,type); }, },
					],
				});
				await alert.present();
			} else {console.log("es compartir");
			
				 this.compartir(url,id, datos, transaccion_id,type); }
		}
	
	}
	async descargarArchivo(url,id, datos, transaccion_id,type) {
		let option;
		var ruta;
		var name;
		if (transaccion_id) option = transaccion_id;
		else option = datos.imprimir;
		let campo1
		if (datos.date_details) campo1=datos.date_details
		else campo1=datos.number
		if (type=='debt') {
			 ruta=url + transaccion_id + '/1?token=' +this.global.token()
			 name=campo1.replace(/[\/\\]/g, '-') + '-' + ("" + datos.id).split('\\n')[0]+ ".pdf"; // Nombre del archivo que se descargará
		}
		else if (type=='status') {
		ruta=url+'?token=' +this.global.token()
		name='estado_de_cuenta_'+this.global.user_data().full_name+'.pdf'; // Nombre del archivo que se descargará
		}


		
	
		const loading = await this.loadingController.create({ mode: "ios", cssClass: "my-custom-class", message: "Cargando...", });
		await loading.present();
		const timeout = setTimeout(async () => {
			loading.dismiss();

		  }, 5000);
	  
		// Realiza la solicitud GET para obtener el PDF
		fetch(ruta)
		  .then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok.');
			}
			return response.blob();
		})
		  .then(blob => {
			// Crea una URL de objeto para el Blob
			const blobUrl = window.URL.createObjectURL(blob);
	  
			// Crea un elemento de enlace para la descarga
			const a = document.createElement('a');
			a.href = blobUrl;
			a.download =name
	  
			// Simula un clic en el enlace para iniciar la descarga
			a.click();
	  
			// Libera la URL del objeto cuando ya no se necesita
			window.URL.revokeObjectURL(blobUrl);
			loading.dismiss();
		  })
		  .catch(error => {
			console.error('Error al descargar el PDF:', error);
			this.actionsService.toast_error('Error al descargar el PDF')
			loading.dismiss();
		  });
	  }


	
	async download_http(url,datos, transaccion_id, type) {
		//console.log(url);
		if (Capacitor.platform == "android") {
			this.androidPermissions
				.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE)
				.then(
					(result) => console.log("Has permission?", result.hasPermission),
					(err) => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE)
				);
			this.androidPermissions.requestPermissions([
				this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE,
				this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE,
			]);
		}
		const loading = await this.loadingController.create({
			mode: "ios", cssClass: "my-custom-class", message:'Cargando...',
		});
		await loading.present();
		const timeout = setTimeout(async () => {
			loading.dismiss();
		
		  }, 5000);
	  
		let option;
		var ruta;
		var name;
		if (transaccion_id) option = transaccion_id;
		else option = datos.imprimir;
		let campo1
		if (datos.date_details) campo1=datos.date_details
		else campo1=datos.number
		if (type=='debt') {
			ruta=url + transaccion_id + '/1?token=' +this.global.token()
			name=campo1.replace(/[\/\\]/g, '-') + '-' + ("" + datos.id).split('\\n')[0] + ".pdf"
		}
	   else if (type=='status') {
	   ruta=url+'?token=' +this.global.token()
	   name='estado_de_cuenta_'+this.global.user_data().full_name+'.pdf'; // Nombre del archivo que se descargará
	   }
		this.http
			.downloadFile(ruta,
				{},
				{},
				this.file.externalRootDirectory +'Download/' +name
			)
			.then ((dat) => {
				loading.dismiss();
				this.actionsService.toast_succes("Descargado exitosamente, buscar en mis descargas.");
		let option: ScheduleOptions={
		notifications:[
			{id: 1,
        title:  'Descarga completa',
        body: `El archivo  ${ name}.pdf ha sido descargado`,
	
		
		
	
		extra: {
			route:`${this.file.externalRootDirectory +'Download/' + name}`
		  }
	}]
	}
				
					//alert(`${this.file.externalRootDirectory +'Download/' + campo1.replace(/[\/\\]/g, '-') + '-' + ("" + datos.id).split('\\n')[0] + ".pdf"}`)
						LocalNotifications.schedule(option)
						
					
				/*
				LocalNotifications.schedule({
					// id: Number(datos.numero.replace('-', '')),
					id: Number(Date.now()),
					title: 'Descarga completa',
					text: `El archivo  ${  campo1.replace(/[\/\\]/g, '-') + '-' + ("" + datos.id).split('\\n')[0] + ".pdf"}.pdf ha sido descargado`,
					trigger: { in: 0, unit: ELocalNotificationTriggerUnit.SECOND },
					icon: 'assets/logo/3.png',
					foreground: true,
					data: { fileUrl: this.file.externalRootDirectory +'Download/' + campo1.replace(/[\/\\]/g, '-') + '-' + ("" + datos.id).split('\\n')[0] + ".pdf" }
				});
				// Agrega un listener para el evento 'click' de la notificación
				LocalNotifications.on('click').subscribe(async res => {
					const fileUrl = res.data.fileUrl;
					await this.fileOpener.open(fileUrl, "application/pdf");
				});
				*/
			})
			.catch((error) => {
				loading.dismiss();
				this.actionsService.toast_error(error.error);
				
			});
	}
	async error() {
		const alert = await this.alertController.create({ header: 'Error', mode: 'ios', message: 'No se pudo descargar el PDF, intente nuevamente.', buttons: ['OK'] });
		await alert.present();
	  }
		

		
	async compartir(url,id, datos, transaccion_id,type) {
	
		
		const loading = await this.loadingController.create({ mode: "ios", cssClass: "my-custom-class", message: "Cargando...", });
		await loading.present();
		const timeout = setTimeout(async () => {
			loading.dismiss();
		
		  }, 8000);
	  
		//if(json.ext=="pdf"){
		let option;
		var ruta;
		var name;
		if (transaccion_id) option = transaccion_id;
		else option = datos.imprimir;
		let campo1
		if (datos.date_details) campo1=datos.date_details
		else campo1=datos.number
		if (type=='debt') {
			ruta=url + option + '/0?token=' + this.global.token()
			name=campo1.replace(/[\/\\]/g, '-') + '-' + ("" + datos.id).split('\\n')[0]+ ".pdf"; // Nombre del archivo que se descargará
	   }
	   else if (type=='status') {
	   ruta=url+'?token=' +this.global.token()
	   name='estado_de_cuenta_'+this.global.user_data().full_name+'.pdf'; // Nombre del archivo que se descargará
	   }

		this.http.sendRequest(ruta,
			{ method: "get", responseType: "blob", })
			.then(async (data) => {
				this.convertBlobToBase64(data.data)
				.then((base64Data) => {
				  // Aquí puedes usar el valor de base64Data, que contiene la respuesta HTML en base64
				  loading.dismiss();
				  FileSharer.share({ filename:name,base64Data, contentType: "application/pdf", }).then(() => {
					  // do sth
				  }).catch(error => { console.error("File sharing failed", error.message); });;

				})
				.catch((error) => {
				  // Manejar errores si es necesario
				  console.error(error);
				});
				
				
			})
			.catch((error) => {
				loading.dismiss();
				this.actionsService.toast_error("Error, intente nuevamente, si el error persiste intente más tarde"); console.log(error); // error message as string
			});
	}

	convertBlobToBase64 = async (blob: Blob): Promise<string> => {
		const buffer = await blob.arrayBuffer();
		const base64String = btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
		return base64String;
	  };
	}

export function getFileReader(): FileReader {
	const fileReader = new FileReader();
	const zoneOriginalInstance = (fileReader as any)["_zone_symbol_originalInstance"];
	return zoneOriginalInstance || fileReader;
}