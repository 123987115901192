import { Injectable } from '@angular/core';
import { urls } from '../../environments/environment';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';
import { ActionsService } from './ActionService.service';

@Injectable({ providedIn: 'root' })
export class DebtsService {
	residence() { return JSON.parse(localStorage.getItem("profile")).residences };
	business() { return JSON.parse(localStorage.getItem("business")).user_id };
	private sugerenciaCreadaSource = new Subject<void>();
	sugerenciaCreada$ = this.sugerenciaCreadaSource.asObservable();
	constructor(private http: HttpService, public global:ActionsService) { }

	

    viewDebts(data, load) {
		
		return new Promise((resolve, reject) => {
		
			const residencia=''
			if (data.type==='social_area') {
				const residencia=''
			}else{
				const residencia=this.residence()[0].id
			}
			this.http.get(urls.debts  + '?token='+this.global.token()+'& residence_id='+residencia+'& start='+data.start +'& limit='+data.limit+'& search='+data.search+'& status_paid='+data.status+'& type='+data.type, load)
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}
	
	DetailDebts(data) {
		
		return new Promise((resolve, reject) => {
	
			
			this.http.get(urls.debts+'/' +data + '?token='+this.global.token(), "Cargando...")
				.then(async (res: any) => {
					resolve(res);
				}, error => {
					reject({ message: error.message, error })
				})
		})
		
	}

	

}
