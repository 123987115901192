import { BillComponent } from './form/bills/bills.component';
import { TermComponent } from './term-modal/term.component';
import { IonicModule } from '@ionic/angular';
import { ClientComponent } from './form/client/client.component';
import { ViewCustomerDataComponent } from './popover/view-customer-data/view-customer-data.component';
import { HeaderComponent } from './header/header.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewServicioDataComponent } from './popover/view-servicio-data/view-servicio-data.component';
import { ViewVisitDataComponent } from './popover/view-visit-data/view-visit-data.component';
import { ViewObsercationDataComponent } from './popover/view-observation-data/view-observation-data.component';
import { FooterComponent } from './footer/footer.component';
import { ReservasComponent } from './form/reservas/reservas.component';

import { PublicacionesComponent } from './form/publicaciones/publicaciones.component';
import { PollComponent } from './form/polls/poll.component';
import { ServiciosComponent } from './form/servicios/servicios.component';
import { MovementComponent } from './form/movement/movement.component';
import { SheetComponent } from './sheet-modal/sheet.component';
import { SugerenciasComponent } from './form/sugerencias/sugerencias.component';
import { VisitComponent } from './form/visit/visit.component';
import { BitacoraComponent } from './form/bitacora/bitacora.component';
import { ViewBusinessComponent } from './popover/view-business/view-business.component';
import { DebtComponent } from './form/debts/debt.component';

import { ViewComponent } from './modal/view.component';
import { ModalQRComponent } from './modal-qr/modal-qr.component';
import { GenerateQRComponent } from './generate-qr/generate-qr.component';
import { InputComponent } from './input/input.component';
import { DatePipe } from '@angular/common';
import { NgxSummernoteModule } from 'ngx-summernote';
import { QRCodeModule } from 'angularx-qrcode';
import * as html2canvas from 'html2canvas';
import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';
import { GuardianVisitComponent } from './form/guardian-visit/guardian-visit.component';
import { AppComponent } from '../app.component';
import { IonicSelectableModule } from 'ionic-selectable';

@NgModule({
    entryComponents: [

    ],
    providers: [
        FooterComponent,
        DatePipe,
    ],
    declarations: [
        AppComponent,
        HeaderComponent,
        ViewCustomerDataComponent,
        ClientComponent,
        ViewServicioDataComponent,
        ViewVisitDataComponent,
        ViewObsercationDataComponent,
        FooterComponent,
        ReservasComponent,

        PublicacionesComponent,
        ServiciosComponent,
        MovementComponent,
        SheetComponent,
        SugerenciasComponent,
        VisitComponent,
        GuardianVisitComponent,
        BitacoraComponent,
        ViewBusinessComponent,
        PollComponent,
        InputComponent,
        DebtComponent,
        BillComponent,
        ViewComponent,
        TermComponent,
        GenerateQRComponent,
        ModalQRComponent
    ],
    exports: [
        HeaderComponent,
        ViewCustomerDataComponent,
        ClientComponent,
        ViewServicioDataComponent,
        ViewVisitDataComponent,
        ViewObsercationDataComponent,
        FooterComponent,
        SheetComponent,
        ReservasComponent,
        ServiciosComponent,
        MovementComponent,
        SugerenciasComponent,
        VisitComponent,
        GuardianVisitComponent,
        PollComponent,
        InputComponent,
        ViewBusinessComponent,
        DebtComponent,
        BillComponent,
        ViewComponent,
        TermComponent,
        ModalQRComponent,
        GenerateQRComponent,AppComponent
    ],
    imports: [
        CommonModule,
        IonicSelectableModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        NgxScannerQrcodeModule,
        NgxSummernoteModule,
        QRCodeModule,
        IonicModule.forRoot(),
        
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
