export const environment = {
	production: false,
	version:'1.0.0'
};

export const politic = 'https://app.urbtic.com/politicas_privacidad.html' //Aquì va la url
//export const baseUrl = 'http://localhost:8001/' //Aquì va la url
export const baseUrl = 'https://api-test.urbtic.com/' //Aquì va la url
//export const baseUrl = 'https://api-prod.urbtic.com/' //Aquì va la url
export const urls = {
	get_business : baseUrl + 'get-business',
	
	login: baseUrl + 'login',
	logout: baseUrl + 'logout',
	suggestion:baseUrl+ 'issues',
	service:baseUrl+'services',
	debts: baseUrl+'debts',
	posts:baseUrl + 'posts',
	printDebt:baseUrl+'printdebt/',
	printpayment:baseUrl+'printpayment/',
	hours:baseUrl+'debts-available-hours',
	storeRent:baseUrl+'storeRent',
	new_password:baseUrl+'update-password',
	poll:baseUrl+'polls',
	visit:baseUrl+'visits',
	bitacora:baseUrl+'bitacoras',
	residence:baseUrl+'residences',
	movement:baseUrl+'movementsVisit',
	taxes:baseUrl+'taxes',
	printstatus:baseUrl+'printstatus',
	incomes:baseUrl+'incomes',
	users:baseUrl+'user-reload'
	};
